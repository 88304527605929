import React from "react";

import ErrorIcon from "@mui/icons-material/ReportGmailerrorred";
import { Box, Button, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { ContainerPaper } from "../core/ContainerPaper/ContainerPaper";

type Props = Readonly<{
  title?: string;
  body?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
  button?: {
    onClick: () => void;
    title: string;
  };
}>;

export const GenericErrorBox: React.FC<Props> = ({
  Icon,
  title,
  body,
  button,
}) => {
  const ComputedIcon = Icon ?? ErrorIcon;
  const computedTitle = title ?? "Something went wrong!";
  const computedBody =
    body ??
    "An unexpected error occurred and our team has already been notified. Try refreshing the page in a few minutes!";

  return (
    <Box
      style={{
        maxWidth: "35em",
        margin: "0 auto",
      }}
      paddingTop={6}
    >
      <ContainerPaper>
        <Box display="flex" flexDirection="row" alignItems="center">
          <ComputedIcon fontSize="large" color="error" />

          <Typography variant="h4" color="error" marginLeft={1}>
            {computedTitle}
          </Typography>
        </Box>

        <Box marginTop={2}>
          <Typography>{computedBody}</Typography>
        </Box>

        {button ? (
          <Box marginTop={3} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={button.onClick}>
              {button.title}
            </Button>
          </Box>
        ) : undefined}
      </ContainerPaper>
    </Box>
  );
};
