import React from "react";

import {
  AnyVariables,
  CombinedError,
  OperationContext,
  QueryState,
} from "urql";

import { GenericErrorBox } from "../components/commons/GenericErrorBox";
import { datadogRum } from "@datadog/browser-rum";

const emptyDataError = new CombinedError({ graphQLErrors: ["Empty data"] });

export type ExecuteQuery = (
  opts?: Partial<OperationContext> | undefined,
) => void;

type HandleQueryDataConfig = {
  renderError: (error: CombinedError) => React.ReactElement<unknown>;
};

const handleQueryDataDefaultConfig: HandleQueryDataConfig = {
  renderError: () => <GenericErrorBox />,
};

/**
 * Utility function to be used inside urql components
 * in order to handle the data returned by the query.
 * This leverages Suspense and Error Boundaries to manage
 * the loading and error states.
 *
 * Example:
 * ```tsx
 * <PortfolioRouteComponent
 *   variables={{ investorId, cutoffs }}
 *   children={handleQueryData(data => (
 *     // Manage your `data` here...
 *   ))}
 * />
 * ```
 */
export const handleQueryData =
  <T, V extends AnyVariables>(
    dataHandler: (
      data: T,
      executeQuery: ExecuteQuery,
    ) => React.ReactElement<unknown, string>,
    config?: Partial<HandleQueryDataConfig>,
  ) =>
  (queryState: QueryState<T, V>): React.ReactElement => {
    const { data, error, executeQuery } = queryState;
    const { renderError }: HandleQueryDataConfig = {
      ...handleQueryDataDefaultConfig,
      ...config,
    };
    const status = error?.response?.status as unknown;

    if (error) {
      // If the error is a 401 or a 403, we don't want to render the error
      // since the errorExchange will redirect the user to the login page.
      if (status === 401 || status === 403) {
        return <></>;
      }

      // Send this error to DataDog.
      datadogRum.addError(error);

      return renderError(error);
    }
    if (typeof data === "undefined") return renderError(emptyDataError);

    return dataHandler(data, executeQuery);
  };

/**
 * URQL context to disable suspense.
 */
export const disableSuspenseContext: Partial<OperationContext> = {
  suspense: false,
};
