import React, { PropsWithChildren } from "react";
import {
  Dialog as BaseDialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { Button } from "../Button/Button";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";
import { P, match } from "ts-pattern";
import { NotificationError } from "../Icon/Icon";

type DialogTitle =
  | {
      title: string;
      titleHighlighted?: string;
    }
  | {
      errorTitle: string;
    };

type Props = PropsWithChildren<
  {
    open: boolean;
    submitButtonText?: string;
    submitButtonDisabled?: boolean;
    submitButtonNegative?: boolean;
    cancelButtonText?: string;
    onClose: () => void;
    onCancel?: () => void;
    onSubmit?: () => void;
    maxWidth?: number;
    minWidth?: number;
    onExited?: () => void;
    noContentPadding?: boolean;
  } & DialogTitle
>;

/**
 * Generic dialog component.
 */
export const Dialog: React.FC<Props> = (props) => {
  const {
    children,
    open,
    onClose,
    submitButtonText,
    submitButtonDisabled,
    submitButtonNegative,
    cancelButtonText,
    onCancel,
    onSubmit,
    maxWidth,
    minWidth,
    onExited,
    noContentPadding,
  } = props;

  const palette = useHeritageV2Palette();

  const showFooter = submitButtonText || cancelButtonText;

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: maxWidth ?? "none",
          minWidth: { sm: "none", md: minWidth ?? undefined },
          padding: "0px",
        },
      }}
      TransitionProps={{
        onExited: onExited,
      }}
    >
      <Box px={3} pt={3}>
        {match(props)
          .with({ title: P.string }, ({ title, titleHighlighted }) => (
            <Box>
              <Typography variant="h3">{title}</Typography>

              {titleHighlighted ? (
                <Box mt={0}>
                  <Typography variant="h2" color={palette.accent}>
                    {titleHighlighted}
                  </Typography>
                </Box>
              ) : undefined}
            </Box>
          ))
          .with({ errorTitle: P.string }, ({ errorTitle }) => (
            <Stack direction="row" alignItems="center" gap={1}>
              <Box color={palette.statusNegative}>
                <NotificationError variant="medium" />
              </Box>

              <Typography variant="h3" color={palette.statusNegative}>
                {errorTitle}
              </Typography>
            </Stack>
          ))
          .otherwise(() => null)}
      </Box>

      <DialogContent
        sx={{
          // This fixes the label issue in the select components
          // when is the first element in the dialog.
          overflow: "initial",
          px: noContentPadding ? undefined : 3,
          pb: showFooter ? undefined : 3,
        }}
      >
        {children}
      </DialogContent>

      {showFooter ? (
        <DialogActions>
          <Stack
            direction="row"
            gap={0}
            flexWrap="wrap"
            width={{ sm: "100%", md: "unset" }}
            px={3}
            pb={3}
          >
            {cancelButtonText ? (
              <Box width={{ sm: "100%", md: "auto" }}>
                <Button variant="secondary" onClick={onCancel}>
                  {cancelButtonText}
                </Button>
              </Box>
            ) : undefined}

            {submitButtonText ? (
              <Box width={{ sm: "100%", md: "auto" }}>
                <Button
                  variant="primary"
                  onClick={onSubmit}
                  negative={submitButtonNegative}
                  disabled={submitButtonDisabled}
                >
                  {submitButtonText}
                </Button>
              </Box>
            ) : undefined}
          </Stack>
        </DialogActions>
      ) : undefined}
    </BaseDialog>
  );
};
