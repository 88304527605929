import { atom } from "recoil";

/**
 * Enable / disable the Portfolio audit.
 */
export const enablePortfolioAudit = atom({
  key: "enablePortfolioAudit",
  default: false,
});

/**
 * Enable / disable the Capital Call documents section.
 */
export const enableCapitalCallDocuments = atom({
  key: "enableCapitalCallDocuments",
  default: false,
});

/**
 * Enable / disable the NAV V2 calculations.
 */
export const enableNavV2 = atom({
  key: "enableNavV2",
  default: false,
});

/**
 * Enable / disable the distribution status badge.
 */
export const enableDistributionStatusBadge = atom({
  key: "enableDistributionStatusBadge",
  default: false,
});
