import Typography from "@mui/material/Typography";
import React from "react";
import { Stack, useTheme } from "@mui/material";
import { function as F } from "fp-ts";
import { userPasswordValidators } from "@heritageholdings/lib-commons-validator/lib/shared/userPassword";
import { NotificationSuccess } from "../core/v2/Icon/Icon";
import { useHeritageV2Palette } from "../../utils/hooks/useHeritageV2Palette";
import { Box } from "../core/v2/Box/Box";

type Constraint = Readonly<{
  description: string;
  validator: (v: string) => boolean;
}>;

const constraints: ReadonlyArray<Constraint> = [
  {
    description: "1 lowercase character",
    validator: F.flow(
      userPasswordValidators.oneLowerCaseCharacter.safeParse,
      (v) => v.success,
    ),
  },
  {
    description: "1 uppercase character",
    validator: F.flow(
      userPasswordValidators.oneUpperCaseCharacter.safeParse,
      (v) => v.success,
    ),
  },
  {
    description: "8 characters minimum",
    validator: F.flow(
      userPasswordValidators.eightCharactersMinimum.safeParse,
      (v) => v.success,
    ),
  },

  {
    description: "1 special character",
    validator: F.flow(
      userPasswordValidators.oneSpecialCharacter.safeParse,
      (v) => v.success,
    ),
  },
  {
    description: "1 number",
    validator: F.flow(
      userPasswordValidators.oneNumber.safeParse,
      (v) => v.success,
    ),
  },
];

type Props = Readonly<{
  password: string;
}>;

/**
 * List all password constraints.
 * Each constraint will show a success icon if the password matches the constraint.
 */
export const PasswordConstraints: React.FC<Props> = ({ password }) => {
  const palette = useHeritageV2Palette();
  const theme = useTheme();

  return (
    <Stack direction="row" flexWrap="wrap" ml={-2} mt={`-${theme.spacing(0)}`}>
      {constraints.map((c) => {
        const isValid = c.validator(password);

        return (
          <Box key={c.description} flex="0 1 30%" ml={2} mt={0}>
            <Stack direction="row" alignItems="center" gap={0}>
              <Box flex="0 0 auto">
                <NotificationSuccess
                  variant="small"
                  stroke={isValid ? palette.statusPositive : undefined}
                />
              </Box>

              <Box flex="0 0 auto">
                <Typography
                  variant="body2"
                  whiteSpace="nowrap"
                  sx={{
                    textDecoration: isValid ? "line-through" : undefined,
                  }}
                >
                  {c.description}
                </Typography>
              </Box>
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};
