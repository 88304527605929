import { Box, Stack, Typography } from "@mui/material";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import React from "react";
import { useIsOnline } from "../../hooks/useIsOnline";

/**
 * it shows a message when the internet connection is lost
 * otherwise it returns null
 * @constructor
 */
export const OfflineBanner = () => {
  const isOnline = useIsOnline();
  if (isOnline) {
    return null;
  }
  return (
    <Box
      sx={{
        backgroundColor: "#bbbbbb",
        cursor: "pointer",
      }}
      width="100%"
      boxSizing="border-box"
      color={"#595959"}
      position="absolute"
      textAlign="center"
      padding={0.3}
      left={0}
      top={0}
      zIndex={2}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={1}
      >
        <CloudOffIcon color={"error"} />
        <Typography variant={"subtitle1"}>
          No internet connection, some functionalities may not work
        </Typography>
      </Stack>
    </Box>
  );
};
