import React, { PropsWithChildren } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { BaseIconPublicProps } from "../../../core/v2/Icon/Icon";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Link as RouterLink } from "react-router-dom";
import { forwardRef } from "react";

const drawerItemHeight = "48px";

/**
 * Custom styled list item button to be used in the sidebar.
 */
const CustomListItemButton: React.FC<
  PropsWithChildren<{
    selected?: boolean;
    route?: string;
    onClick?: () => void;
  }>
> = ({ children, selected, route, onClick }) => (
  <ListItemButton
    component={route ? CustomLink : "button"}
    href={route}
    onClick={onClick}
    sx={(theme) => ({
      height: drawerItemHeight,
      border: `2px solid transparent`,
      padding: "0px",
      paddingLeft: theme.spacing(0),
      borderRadius: 1,
      transition: "none",

      "&:hover": {
        background: "transparent !important",
        border: `2px solid ${theme.palette.heritageV2.text900}`,
      },

      "&.Mui-selected": {
        backgroundColor: `${theme.palette.heritageV2.text900} !important`,
      },

      "&.Mui-selected .MuiTypography-root": {
        color: `${theme.palette.heritageV2.backgroundPage} !important`,
      },
    })}
    selected={selected}
    disableRipple
  >
    {children}
  </ListItemButton>
);

/**
 * Custom link component to be used in the sidebar menu.
 * @see https://mui.com/material-ui/guides/routing/#list
 */
const CustomLink = forwardRef<
  HTMLAnchorElement,
  Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    "ref"
  >
>(({ href, ...props }, ref) => (
  <RouterLink to={href ?? ""} ref={ref} {...props} />
));

type Props = {
  slug: string;
  name: string;
  route?: string;
  Icon?: React.FC<BaseIconPublicProps>;
  selected?: boolean;
  onClick?: () => void;
};

/**
 * Sidebar drawer item component.
 */
export const SidebarDrawerItem: React.FC<Props> = ({
  slug,
  route,
  name,
  Icon,
  selected,
  onClick,
}) => {
  const palette = useHeritageV2Palette();

  return (
    <ListItem
      key={slug}
      sx={{
        height: "3rem",

        "&:not(:first-child)": {
          mt: 0,
        },
      }}
      disablePadding
    >
      <CustomListItemButton route={route} selected={selected} onClick={onClick}>
        {Icon ? (
          <ListItemIcon
            sx={(theme) => ({
              minWidth: "auto",
              marginRight: theme.spacing(0),
            })}
          >
            <Icon
              stroke={selected ? palette.backgroundPage : palette.text900}
              variant="medium"
            />
          </ListItemIcon>
        ) : undefined}

        <ListItemText
          primaryTypographyProps={{
            variant: "menuItem",
          }}
          primary={name}
          sx={(theme) => ({
            color: theme.palette.heritageV2.text900,
          })}
        />
      </CustomListItemButton>
    </ListItem>
  );
};
