import React, { useCallback, useMemo } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { ChevronDown } from "../Icon/Icon";

export type InvestorSelectOption = {
  key: string;
  value: string;
  label: string;
};

type Props = {
  options: Array<InvestorSelectOption>;
  onChange?: (value: string) => void;
  value: string;
};

/**
 * The select component used for the investors in the
 * top navbar.
 */
export const InvestorsSelect: React.FC<Props> = ({
  options,
  value,
  onChange,
}) => {
  const palette = useHeritageV2Palette();

  const handleChange = useCallback(
    (evt: SelectChangeEvent<string>) => {
      onChange?.(evt.target.value);
    },
    [onChange],
  );

  const renderOptions = useMemo(
    () =>
      options.map(({ key, value, label }) => (
        <MenuItem key={key} value={value}>
          {label}
        </MenuItem>
      )),
    [options],
  );

  return (
    <Select
      value={value}
      sx={(theme) => ({
        color: palette.invariantWhite,
        fontFamily: "'Playfair Display', sans-serif;",
        fontSize: "1.2rem",
        width: "100%",
        display: "grid",
        maxWidth: 500,

        "& .MuiOutlinedInput-input": {
          paddingRight: `${theme.spacing(4)} !important`,
          maxWidth: "100%",
          width: "auto",
        },

        "& .MuiOutlinedInput-notchedOutline": {
          border: "none !important",
        },

        "& .MuiSelect-icon": {
          marginTop: "-5px",
          right: `4px`,
        },

        [theme.breakpoints.up("md")]: {
          fontSize: "1.5rem",

          "& .MuiSelect-icon": {
            marginTop: "-1px !important",
          },
        },
      })}
      IconComponent={(props) => (
        <ChevronDown variant="medium" stroke={palette.neutral500} {...props} />
      )}
      onChange={handleChange}
    >
      {renderOptions}
    </Select>
  );
};
