import React, { HTMLProps } from "react";
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material";
import { CheckIcon } from "../Icon/Icon";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";

const EmptyCheckboxUI: React.FC<HTMLProps<HTMLInputElement>> = ({
  checked,
}) => {
  const palette = useHeritageV2Palette();

  return (
    <Box
      width={32}
      height={32}
      sx={{
        borderWidth: "1px",
        borderColor: checked ? palette.accent : palette.neutral700,
        borderStyle: "solid",
        borderRadius: "4px",
        backgroundColor: checked ? palette.accent : "transparent",
      }}
    >
      {checked ? (
        <CheckIcon variant="medium" stroke={palette.invariantWhite} />
      ) : undefined}
    </Box>
  );
};

type Props = {
  label: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
  error?: boolean;
};

/**
 * A checkbox input component with a label integrated.
 */
export const Checkbox: React.FC<Props> = ({
  label,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          icon={<EmptyCheckboxUI />}
          checkedIcon={<EmptyCheckboxUI checked />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
        />
      }
      label={label}
    />
  );
};
