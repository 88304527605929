import { Investor } from "../generated/urql";

/**
 * Check whether an Investor is in pending approval.
 *
 * This function will mimic the logic in
 * https://github.com/heritageholdings/iconic/blob/master/app/ICONIC/Models/Investor.php#L445-L452.
 */
export function isInvestorPending(
  investor: Pick<
    Investor,
    "approvedAt" | "rejectedAt" | "adminApprovedAt" | "adminRejectedAt"
  >,
): boolean {
  const isApproved = investor.approvedAt !== null;
  const isRejected = investor.rejectedAt !== null;
  const isFmPending = !isApproved && !isRejected;

  const isAdminApproved = investor.adminApprovedAt !== null;
  const isAdminRejected = investor.adminRejectedAt !== null;
  const isAdminPending = !isAdminApproved && !isAdminRejected;

  return isFmPending || isAdminPending;
}
