import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

/**
 *  Base wrapper for the content of a page which handles the
 *  max width and the responsiveness.
 */
export const ContentWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <Box maxWidth="80em" marginX="auto">
    {children}
  </Box>
);
