import { TextField } from "@mui/material";
import React, {
  ChangeEventHandler,
  ComponentProps,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  forwardRef,
} from "react";

type Props = {
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  helperText?: string;
  type?: HTMLInputTypeAttribute;
  error?: boolean;
  required?: boolean;
} & Pick<ComponentProps<typeof TextField>, "disabled">;

/**
 * Base input component.
 */
export const Input = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      value,
      defaultValue,
      onChange,
      onBlur,
      helperText,
      type,
      error,
      disabled,
      required,
    },
    ref,
  ) => {
    return (
      <TextField
        ref={ref}
        disabled={disabled}
        label={label}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        type={type}
        sx={{
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },

          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        }}
        error={error}
        required={required}
      />
    );
  },
);
