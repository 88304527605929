import { Dialog } from "../core/v2/Dialog/Dialog";
import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLayoutContext } from "../../routes/LayoutRoute/LayoutRoute";
import { useRecoilState } from "recoil";
import { lastPasskeySuggestionDate } from "../../state/suggestPasskey";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { useNavigate, useParams } from "react-router-dom";
import { tracker } from "../../utils/tracker";

const displayCreationAlertInterval = 7 * 24 * 60 * 60 * 1000; // 7 days

export const loginWithPasswordLocalStorageKey = "loginWithPassword";

/**
 * This alert is displayed to the user if he logs in with password and can activate passkey.
 * @constructor
 */
const PasskeyCreationDialog = () => {
  const { authUser } = useLayoutContext();
  const [lastPasskeySuggestion, setLastPasskeySuggestion] = useRecoilState(
    lastPasskeySuggestionDate,
  );
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { investorId } = useParams();

  useEffect(() => {
    // We read the value and consume it, in order to avoid to display the alert multiple times
    // if the user refresh or reload the page
    const loginWithPassword = localStorage.getItem(
      loginWithPasswordLocalStorageKey,
    );
    localStorage.removeItem(loginWithPasswordLocalStorageKey);

    if (
      authUser.authenticators.length === 0 &&
      browserSupportsWebAuthn() &&
      loginWithPassword === "true" &&
      Date.now() - lastPasskeySuggestion > displayCreationAlertInterval
    ) {
      tracker.trackEvent({ name: "PasskeyRegistrationSuggestionAlert" });
      setOpen(true);
    }
    // We need to execute this code only once, when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      title={`Simplify your sign-in`}
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => {
        setLastPasskeySuggestion(Date.now());
        setOpen(false);
      }}
      onSubmit={() => {
        setOpen(false);
        navigate(`investor/${investorId}/me/account/security`);
      }}
      cancelButtonText="Remind me later"
      submitButtonText={"Create a passkey"}
      minWidth={400}
      maxWidth={500}
    >
      <Typography>
        With passkeys you can now use your fingerprint, face, or screen lock to
        verify it&apos;s really you, without having to remember a password.
      </Typography>
    </Dialog>
  );
};

export const SuggestPasskeyActivation: React.FC = () => {
  // TODO: Will be another alert if the user has a passkey but doesn't use it to log in (he uses password)
  return <PasskeyCreationDialog />;
};
