import { PasskeyErrors } from "./types";
import * as TE from "fp-ts/TaskEither";
import {
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from "@simplewebauthn/typescript-types";
import {
  startAuthentication,
  startRegistration,
} from "@simplewebauthn/browser";
import * as F from "fp-ts/function";
import {
  requestAuthenticationOptions,
  requestRegistrationOptions,
  verifyAuthentication,
  verifyRegistration,
} from "./network";
import { logPasskeyError, parsePasskeyError } from "./utils";
import { AuthenticationResponseJSONV2 } from "@heritageholdings/lib-commons-validator/lib/shared/passkey";

/**
 * Wrap the {@link startRegistration} function from @simplewebauthn/browser in a {@link TE.TaskEither}
 * in order to easily orchestrate the registration process
 * @param registrationResponse
 */
const startLocalRegistration = (
  registrationResponse: PublicKeyCredentialCreationOptionsJSON,
): TE.TaskEither<PasskeyErrors, RegistrationResponseJSON> =>
  TE.tryCatch(() => startRegistration(registrationResponse), parsePasskeyError);

const startLocalAuthentication = (
  registrationResponse: PublicKeyCredentialRequestOptionsJSON,
): TE.TaskEither<PasskeyErrors, AuthenticationResponseJSONV2> =>
  TE.tryCatch(
    () => startAuthentication(registrationResponse),
    parsePasskeyError,
  );

/**
 * Register a new passkey with the server, in order to be used for authentication.
 * 1. Request the registration options to the server
 * 2. Start the registration process locally
 * 3. Verify the registration with the server
 */
export const registerPasskey = () =>
  F.pipe(
    requestRegistrationOptions(),
    TE.chainW(startLocalRegistration),
    TE.chainW(verifyRegistration),
    logPasskeyError("registerPasskey"),
  );

export const authenticateWithPasskey = (remember: boolean | undefined) =>
  F.pipe(
    requestAuthenticationOptions(),
    TE.chainW(startLocalAuthentication),
    TE.chainW((x) => verifyAuthentication({ ...x, remember })),
    logPasskeyError("authenticateWithPasskey"),
  );
