import { useEffect, useState } from "react";
import { SystemStatus } from "../generated/urql";
import { SystemStatusSchema } from "../generated/validation-schema";
import { config } from "../utils/config";
import { EventSourcePolyfill } from "event-source-polyfill";

const statusSubscriptionUrl = new URL(
  config.graphQLUrlPath,

  // The `origin` is the protocol + host + port interpolated
  // string which will be used whether the graphQLUrlPath is
  // a relative path.
  // Read more here: https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
  window.location.origin,
);

statusSubscriptionUrl.searchParams.append(
  "query",
  `
          subscription SystemStatus {
              system
          }
    `,
);
/**
 * subscribe to SSE about system changes and return the value of the system status
 */
export const useSystemStatusSubscription = (): SystemStatus | undefined => {
  const [systemStatus, setSystemStatus] = useState<SystemStatus | undefined>();
  useEffect(() => {
    const eventsource = new EventSourcePolyfill(statusSubscriptionUrl.href, {
      withCredentials: true, // This is required for cookies,
      headers: { "x-heritage-action": "graphql" },
    });

    eventsource.addEventListener("next", (event) => {
      // JSON.parse could fail, and data is poorly typed and we need to cast it
      const { data } = event as unknown as { data: string };
      try {
        const payload = JSON.parse(data);
        const maybeStatus = SystemStatusSchema.safeParse(payload.data.system);
        if (maybeStatus.success) {
          setSystemStatus(maybeStatus.data);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });

    return () => {
      eventsource.close();
    };
  }, [setSystemStatus]);
  return systemStatus;
};
