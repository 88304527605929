import React, { useCallback } from "react";
import {
  MaterialDesignContent,
  SnackbarProvider,
  useSnackbar,
} from "notistack";
import { PropsWithChildren } from "react";
import { styled } from "@mui/material";
import {
  NotificationError,
  NotificationSuccess,
} from "../components/core/v2/Icon/Icon";
import { useHeritageV2Palette } from "./hooks/useHeritageV2Palette";

const BaseNotification = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent": {
    fontFamily: theme.typography.fontFamily,
  },
}));

const V2BaseNotification = styled(MaterialDesignContent)(({ theme }) => ({
  "&.notistack-MuiContent": {
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,

    boxShadow: "none",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,

    maxWidth: 450,

    "& > div": {
      padding: 0,

      "& > svg": {
        marginRight: theme.spacing(2),
      },
    },

    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.heritageV2.statusPositive,
    },

    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.heritageV2.statusNegative,
    },
  },
}));

type Props = PropsWithChildren<{
  isThemeV2Enabled: boolean;
}>;

/**
 * Main Notifications Provider. This will allow to show top-right notifications
 * in the application.
 */
export const NotificationsProvider: React.FC<Props> = ({
  children,
  isThemeV2Enabled,
}) => {
  const palette = useHeritageV2Palette();

  const NotificationComponent = isThemeV2Enabled
    ? V2BaseNotification
    : BaseNotification;

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      iconVariant={
        isThemeV2Enabled
          ? {
              success: (
                <NotificationSuccess
                  stroke={palette.invariantWhite}
                  variant="medium"
                />
              ),

              error: (
                <NotificationError
                  stroke={palette.invariantWhite}
                  variant="medium"
                />
              ),
            }
          : undefined
      }
      Components={{
        default: NotificationComponent,
        success: NotificationComponent,
        error: NotificationComponent,
        warning: NotificationComponent,
        info: NotificationComponent,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

/**
 * Hook to use the notifications in the application.
 */
export function useNotifications() {
  const { enqueueSnackbar } = useSnackbar();

  const successNotification = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: "success",
      });
    },
    [enqueueSnackbar],
  );

  const errorNotification = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: "error",
      });
    },
    [enqueueSnackbar],
  );

  return {
    successNotification,
    errorNotification,
  };
}
