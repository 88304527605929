import React from "react";
import { Typography } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Button } from "../Button/Button";
import { Box } from "../Box/Box";

type Props = {
  message: string;
  ctaText?: string;
  onCtaClick?: () => void;
};

/**
 * Placeholder component when no data are displayed.
 */
export const NoDataPlaceholder: React.FC<Props> = ({
  message,
  ctaText,
  onCtaClick,
}) => {
  const palette = useHeritageV2Palette();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      borderRadius={2}
      overflow="auto"
      border={`3px dashed ${palette.neutral500}`}
      flexDirection="column"
      gap={2}
      p={2}
      boxSizing="border-box"
    >
      <Typography variant="largeMessage" textAlign="center">
        {message}
      </Typography>

      {ctaText ? (
        <Box>
          <Button onClick={onCtaClick} variant="primary">
            {ctaText}
          </Button>
        </Box>
      ) : undefined}
    </Box>
  );
};
