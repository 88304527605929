import React, { useMemo } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Logo } from "../../../core/Logo/Logo";
import { CloseIcon, MenuIcon, UserIcon } from "../../../core/v2/Icon/Icon";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Link } from "react-router-dom";
import {
  InvestorSelectOption,
  InvestorsSelect,
} from "../../../core/v2/InvestorsSelect/InvestorsSelect";
import { LayoutRouteQuery } from "../../../../generated/urql";
import { Box } from "../../../core/v2/Box/Box";

type Props = {
  authUser: LayoutRouteQuery["authUser"];
  currentInvestorId?: string;
  onMenuPress?: () => void;
  onInvestorChange?: (investorId: string) => void;
  drawerOpen?: boolean;
  logoLink?: string;
  currentRoute?: string;
};

/**
 * The top navbar component.
 */
export const Navbar: React.FC<Props> = ({
  authUser,
  currentInvestorId,
  onMenuPress,
  onInvestorChange,
  drawerOpen,
  logoLink,
  currentRoute,
}) => {
  const palette = useHeritageV2Palette();
  const DrawerIcon = drawerOpen ? CloseIcon : MenuIcon;

  const isUserProfileRoute = currentRoute === "UserProfileRouteV2";

  const username = useMemo(
    () => `${authUser.firstName} ${authUser.lastName}`,
    [authUser],
  );

  const investors = useMemo<Array<InvestorSelectOption>>(
    () =>
      authUser.investors.map(({ investor }) => ({
        label: investor.businessName,
        key: investor.id,
        value: investor.id,
      })),
    [authUser],
  );

  return (
    <AppBar
      variant="outlined"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflow: "hidden",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box display="flex" alignItems="center" flex="0 0 auto">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuPress}
            sx={(theme) => ({
              display: "none",
              marginLeft: "0px",
              paddingLeft: 0,
              [theme.breakpoints.down("lg")]: { display: "flex" },
            })}
          >
            <DrawerIcon stroke={palette.invariantWhite} variant="medium" />
          </IconButton>

          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("lg")]: { display: "none" },
            })}
          >
            <Link to={logoLink ?? ""}>
              <Logo fill="white" height={25} />
            </Link>
          </Box>
        </Box>

        <Box flex="0 1 auto">
          {currentInvestorId ? (
            <InvestorsSelect
              options={investors}
              value={currentInvestorId}
              onChange={onInvestorChange}
            />
          ) : undefined}
        </Box>
        <Link
          to={`investor/${currentInvestorId}/me/account`}
          style={{ textDecoration: "none" }}
        >
          <Box display="flex" alignItems="center" gap={1} flex="0 0 auto">
            {username ? (
              <>
                <Typography
                  variant="menuItem"
                  color={
                    isUserProfileRoute
                      ? palette.accentLight
                      : palette.neutral500
                  }
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  {username}
                </Typography>

                <UserIcon
                  variant="medium"
                  stroke={
                    isUserProfileRoute
                      ? palette.accentLight
                      : palette.invariantWhite
                  }
                />
              </>
            ) : undefined}
          </Box>
        </Link>
      </Toolbar>
    </AppBar>
  );
};
