import {
  Chip,
  Divider,
  List,
  ListItem,
  Drawer as MuiDrawer,
  SwipeableDrawer as MuiSwipeableDrawer,
  Stack,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { IterableElement } from "type-fest";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import { FeatureFlag, LayoutRouteQuery } from "../../../../generated/urql";
import { useRoutesResolver } from "../../../../lib/hooks/useRoutesResolver";
import { RouteClasses } from "../../../../routes/RootRoute/RootRoute";
import {
  enableCapitalCallDocuments,
  enableDistributionStatusBadge,
  enableNavV2,
  enablePortfolioAudit,
} from "../../../../state/developer";
import { Box } from "../../../core/v2/Box/Box";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import {
  AssetsIcon,
  BaseIconPublicProps,
  CashflowIcon,
  ConfigureIcon,
  DocumentsIcon,
  OpportunitiesIcon,
  PortfolioIcon,
  TransactionsIcon,
} from "../../../core/v2/Icon/Icon";
import { DeveloperConsole } from "../DeveloperConsole/DeveloperConsole";
import { EarlyAdopterConsole } from "../EarlyAdopterConsole/EarlyAdopterConsole";
import { SidebarDrawerItem } from "./SidebarDrawerItem";

// In the parent width we are considering the border size
// both left and right of 2px. So the actual width of the
// drawer is the value in the variable minus 4px.
const drawerClosedWidth = "52px";
const drawerOpenWidth = "224px";

/**
 * The sidebar drawer component.
 */
export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => {
  const transition = theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: "0.2s",
  });

  const openMediaQuery = theme.breakpoints.up("lg");

  return {
    width: `calc(${drawerClosedWidth} + ${theme.spacing(3)})`,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    transition,

    [openMediaQuery]: {
      width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
    },

    "& .MuiDrawer-paper": {
      width: `calc(${drawerClosedWidth} + ${theme.spacing(3)})`,
      boxSizing: "border-box",
      background: "transparent",
      border: 0,
      transition,

      [openMediaQuery]: {
        width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
      },
    },

    "& .MuiListItem-root": {
      width: drawerClosedWidth,
      transition,

      [openMediaQuery]: {
        width: drawerOpenWidth,
      },
    },

    "& .MuiList-root.secondary": {
      opacity: 0,
      transition,
      pointerEvents: "none",

      [openMediaQuery]: {
        opacity: 1,
        pointerEvents: "auto",
      },

      "& *": {
        pointerEvents: "none",

        [openMediaQuery]: {
          pointerEvents: "auto",
        },
      },
    },

    "& .MuiListItemText-root": {
      opacity: 0,
      transition,

      [openMediaQuery]: {
        opacity: 1,
      },
    },
  };
});

/**
 * The sidebar drawer component.
 * This component is used when the screen matches
 * a mobile device.
 */
export const SwipeableDrawer = styled(MuiSwipeableDrawer)(({ theme }) => {
  return {
    width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,

    "& .MuiDrawer-paper": {
      width: `calc(${drawerOpenWidth} + ${theme.spacing(3)})`,
    },
  };
});

export type DrawerMenuItem = {
  slug: string;
  name: string;
  Icon?: React.FC<BaseIconPublicProps>;
  route?: string;
  selected?: boolean;
  onClick?: () => void;
};

type Props = {
  authUser: Pick<
    LayoutRouteQuery["authUser"],
    "firstName" | "lastName" | "featureFlags"
  >;
  userInvestors: LayoutRouteQuery["authUser"]["investors"];
  selectedUserInvestor?: IterableElement<
    LayoutRouteQuery["authUser"]["investors"]
  >;
  currentRoute?: string;
  drawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

/**
 * The sidebar menu and drawer when the screen matches
 * a mobile device.
 */
export const SidebarDrawer: React.FC<Props> = ({
  authUser,
  currentRoute,
  selectedUserInvestor,
  drawerOpen,
  onDrawerOpen,
  onDrawerClose,
}) => {
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();
  const [showDeveloperConsole, setShowDeveloperConsole] = useState(false);
  const [showEarlyAdopterConsole, setShowEarlyAdopterConsole] = useState(false);
  const hasDeveloperModeFeatureFlag = useMemo(
    () => authUser.featureFlags.includes(FeatureFlag.EnableDeveloperMode),
    [authUser.featureFlags],
  );
  const hasEarlyAdopterFeatureFlag = useMemo(
    () => authUser.featureFlags.includes(FeatureFlag.EnableEarlyAdopterMode),
    [authUser.featureFlags],
  );
  const theme = useTheme();
  const showLeftDrawer = useMediaQuery(theme.breakpoints.up("md"));

  const isPortfolioV2AuditEnabled = useRecoilValue(enablePortfolioAudit);

  const isCapitalCallDocumentsEnabled = useRecoilValue(
    enableCapitalCallDocuments,
  );

  const isNavV2Enabled = useRecoilValue(enableNavV2);

  const isDistributionStatusBadgeEnabled = useRecoilValue(
    enableDistributionStatusBadge,
  );

  const MenuItems = useMemo(
    () => () => (
      <>
        <SidebarDrawerItem
          slug="portfolio"
          Icon={PortfolioIcon}
          name={"Portfolio"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("PortfolioRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "PortfolioRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="assets"
          Icon={AssetsIcon}
          name={"Assets"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("InvestmentsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "InvestmentsRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="new-opportunities"
          Icon={OpportunitiesIcon}
          name={"Invest"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("AssetsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={
            currentRoute === "AssetsRoute" || currentRoute === "AssetRoute"
          }
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="cashflow-simulator"
          Icon={CashflowIcon}
          name={"Cash Flow"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("CashflowSimulatorRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "CashflowSimulatorRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="data-room"
          Icon={DocumentsIcon}
          name={"Data Room"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("DocumentsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "DocumentsRoute"}
          onClick={onDrawerClose}
        />
        <SidebarDrawerItem
          slug="transactions"
          Icon={TransactionsIcon}
          name={"Transactions"}
          route={
            selectedUserInvestor
              ? generatePathForRoute("TransactionsRoute", {
                  investorId: selectedUserInvestor.investor.id,
                })
              : undefined
          }
          selected={currentRoute === "TransactionsRoute"}
          onClick={onDrawerClose}
        />
        {hasDeveloperModeFeatureFlag && (
          <>
            <Divider sx={{ mt: 1 }} />
            <SidebarDrawerItem
              slug="developer"
              Icon={ConfigureIcon}
              name={"Feature Flags"}
              onClick={() => setShowDeveloperConsole(true)}
            />
            <ListItem>
              <Stack direction="column" spacing={1} alignItems={"flex-start"}>
                <Chip
                  label="AUDIT TRAIL"
                  size="small"
                  icon={
                    isPortfolioV2AuditEnabled ? (
                      <RadioButtonCheckedIcon fontSize="small" color="info" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="small" />
                    )
                  }
                  variant={isPortfolioV2AuditEnabled ? "filled" : "outlined"}
                />
                <Chip
                  label="CC DOCUMENTS"
                  size="small"
                  icon={
                    isCapitalCallDocumentsEnabled ? (
                      <RadioButtonCheckedIcon fontSize="small" color="info" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="small" />
                    )
                  }
                  variant={
                    isCapitalCallDocumentsEnabled ? "filled" : "outlined"
                  }
                />
                <Chip
                  label="NAV V2"
                  size="small"
                  icon={
                    isNavV2Enabled ? (
                      <RadioButtonCheckedIcon fontSize="small" color="info" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="small" />
                    )
                  }
                  variant={isNavV2Enabled ? "filled" : "outlined"}
                />
                <Chip
                  label="Distribution Status"
                  size="small"
                  icon={
                    isDistributionStatusBadgeEnabled ? (
                      <RadioButtonCheckedIcon fontSize="small" color="info" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="small" />
                    )
                  }
                  variant={
                    isDistributionStatusBadgeEnabled ? "filled" : "outlined"
                  }
                />
              </Stack>
            </ListItem>
          </>
        )}
        {hasEarlyAdopterFeatureFlag && (
          <SidebarDrawerItem
            slug="early-adopter"
            Icon={ConfigureIcon}
            name={"Early Adopter"}
            onClick={() => setShowEarlyAdopterConsole(true)}
          />
        )}
      </>
    ),
    [
      currentRoute,
      selectedUserInvestor,
      onDrawerClose,
      generatePathForRoute,
      hasDeveloperModeFeatureFlag,
      hasEarlyAdopterFeatureFlag,
      isCapitalCallDocumentsEnabled,
      isNavV2Enabled,
      isPortfolioV2AuditEnabled,
    ],
  );

  return (
    <>
      {showLeftDrawer ? (
        <Drawer variant="permanent">
          <Toolbar />

          <Box
            sx={{
              overflow: "hidden",
              mt: 3,
              ml: 3,
              height: "100%",
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              height="100%"
            >
              <List
                sx={{
                  // This will fix an inssue in which the borders
                  // of the items are cutted off. This should be
                  // the same size of the said borders.
                  py: "2px",
                }}
              >
                <MenuItems />
              </List>
            </Stack>
          </Box>
        </Drawer>
      ) : undefined}

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={onDrawerOpen ?? (() => undefined)}
        onClose={onDrawerClose ?? (() => undefined)}
      >
        <Toolbar />

        <Stack
          direction="column"
          justifyContent="space-between"
          height="100%"
          sx={{
            p: 1,
          }}
        >
          <List>
            <MenuItems />
          </List>
        </Stack>
      </SwipeableDrawer>

      <Dialog
        title="Developer console"
        open={showDeveloperConsole}
        onClose={() => setShowDeveloperConsole(false)}
        minWidth={300}
      >
        <DeveloperConsole user={authUser} />
      </Dialog>

      <Dialog
        title="Early Adopter console"
        open={showEarlyAdopterConsole}
        onClose={() => setShowEarlyAdopterConsole(false)}
        minWidth={300}
      >
        <EarlyAdopterConsole />
      </Dialog>
    </>
  );
};
