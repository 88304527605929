import { PasskeyErrors } from "./types";
import * as F from "fp-ts/function";
import * as E from "fp-ts/Either";
import { parseUnknownError } from "../utils/error";
import * as t from "io-ts";
import * as TE from "fp-ts/TaskEither";
import { datadogRum } from "@datadog/browser-rum";
import { AuthenticatorCreatedFromPlatform } from "@heritageholdings/lib-commons-validator/lib/shared/passkey";
import { isDevelopment } from "../../utils/environment";

export const getPlatformFromUserAgent =
  (): AuthenticatorCreatedFromPlatform => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Android") !== -1) {
      return "android";
    }
    if (userAgent.indexOf("iPhone") !== -1) {
      return "ios";
    }
    if (userAgent.indexOf("Win") !== -1) {
      return "windows";
    }
    if (userAgent.indexOf("Mac") !== -1) {
      return "mac";
    }
    if (userAgent.indexOf("X11") !== -1) {
      return "linux";
    }
    if (userAgent.indexOf("Linux") !== -1) {
      return "linux";
    }
    return "other";
  };

/**
 * Extract the browser name and version from the user agent.
 */
const getBrowserFromUserAgent = (): string => {
  const userAgent = navigator.userAgent;
  const match =
    /(chrome)[ /]([\w.]+)/i.exec(userAgent) ||
    /(safari)[ /]([\w.]+)/i.exec(userAgent) ||
    /(webkit)[ /]([\w.]+)/i.exec(userAgent) ||
    /(opera)(?:.*version|)[ /]([\w.]+)/i.exec(userAgent) ||
    /(msie) ([\w.]+)/i.exec(userAgent) ||
    (userAgent.indexOf("compatible") < 0 &&
      /(mozilla)(?:.*? rv:([\w.]+)|)/i.exec(userAgent)) ||
    [];

  return match[1] || "";
};

const getOSNameFromUserAgent = (): string => {
  const userAgent = navigator.userAgent;
  const match =
    /(windows nt|mac os x|android|linux|ubuntu|iphone|ipad|ipod|blackberry|webos|mobile)/i.exec(
      userAgent,
    ) || [];

  return match[1] || "";
};

export const getCreatedDevice = (): string =>
  `${getOSNameFromUserAgent()} - ${getBrowserFromUserAgent()}`;

const SimpleWebAuthNError = t.type({
  name: t.string,
  code: t.string,
  message: t.string,
});

/**
 * Parse the error returned by the native part of the passkey module, trying to extract
 * semantic error from the native error code
 * @param e
 */
export const parsePasskeyError = (e: unknown): PasskeyErrors =>
  F.pipe(
    e,
    SimpleWebAuthNError.decode,
    E.map((x) => {
      switch (x.code) {
        // Chrome: The user try to create a passkey when one already exists
        case "ERROR_AUTHENTICATOR_PREVIOUSLY_REGISTERED":
          return {
            kind: "excludedCredentialExists",
          } as PasskeyErrors;
        // A generic native error not handled
        default:
          return {
            kind: "native",
            message: parseUnknownError(e).message,
          } as PasskeyErrors;
      }
    }),
    E.getOrElse((): PasskeyErrors => {
      const parsedError = parseUnknownError(e);

      return {
        kind: "native",
        message: parsedError.message,
      };
    }),
  );

export const logPasskeyError =
  (prefix: string) =>
  <A>(fa: TE.TaskEither<PasskeyErrors, A>) =>
    isDevelopment
      ? F.pipe(
          fa,
          TE.mapLeft((e) => {
            // eslint-disable-next-line no-console
            console.error(`[Passkey/${prefix}] :`, JSON.stringify(e));
            return e;
          }),
        )
      : F.pipe(
          fa,
          TE.mapLeft((e) => {
            switch (e.kind) {
              case "generic":
                datadogRum.addError(`Passkey generic/${prefix}: ${e.message}`);
                break;
              case "native":
                datadogRum.addError(`Passkey native/${prefix}: ${e.message}`);
                break;
            }
            return e;
          }),
        );
