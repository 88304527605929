import * as TE from "fp-ts/TaskEither";
import * as F from "fp-ts/function";
import { parseUnknownError } from "../utils/error";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PasskeyErrors } from "./types";
import {
  AuthenticationResponseJSONV2,
  CredentialCreationOptions,
  PublicKeyCredentialRequestOptions,
  RegistrationResponseJSON as HRegistrationResponseJSON,
} from "@heritageholdings/lib-commons-validator/lib/shared/passkey";

import { PathReporter } from "io-ts/PathReporter";
import * as E from "fp-ts/Either";
import { RegistrationResponseJSON } from "@simplewebauthn/typescript-types";
import { getCreatedDevice, getPlatformFromUserAgent } from "./utils";
import { httpClient } from "../../utils/httpClient";

export type PasskeyNetworkError = {
  kind: "network";
  status?: number;
  message: string;
};

/**
 * Wrapper around axios.get that returns a {@link TaskEither} instead of a {@link Promise}
 * @param url
 * @param config
 * @constructor
 */
const AxiosGetTE = (
  url: string,
  config?: AxiosRequestConfig<unknown>,
): TE.TaskEither<PasskeyNetworkError, AxiosResponse> =>
  TE.tryCatch(
    () => httpClient.get(url, config),
    (e) => {
      const parsedError = parseUnknownError(e);
      return {
        kind: "network",
        message: parsedError.message ?? "Generic network error",
      };
    },
  );

/**
 * Wrapper around axios.post that returns a {@link TaskEither} instead of a {@link Promise}
 * @param url
 * @param data
 * @param config
 * @constructor
 */
const AxiosPostTE = (
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig<unknown>,
): TE.TaskEither<PasskeyNetworkError, AxiosResponse> =>
  TE.tryCatch(
    () => httpClient.post(url, data, config),
    (e) => {
      const parsedError = parseUnknownError(e);
      return {
        kind: "network",
        message: parsedError.message ?? "Generic network error",
      };
    },
  );

/**
 * Request the registration options to the server
 */
const getRegistrationOptions = (): TE.TaskEither<
  PasskeyNetworkError,
  AxiosResponse
> =>
  AxiosGetTE(`/webauthn/generate-registration-options`, {
    headers: {
      "x-heritage-action": "register-passkey",
    },
  });

/**
 * Verify the registration options with the server
 * @param registrationResponse
 */
const postVerifyRegistrationOptions = (
  registrationResponse: HRegistrationResponseJSON,
): TE.TaskEither<PasskeyNetworkError, AxiosResponse> =>
  AxiosPostTE("/webauthn/verify-registration", registrationResponse, {
    headers: {
      "x-heritage-action": "register-passkey",
    },
  });

const getAuthenticationOptions = () =>
  AxiosGetTE(
    "/webauthn/v2/generate-authentication-options",

    {
      headers: {
        "x-heritage-action": "login",
      },
    },
  );

const postVerifyAuthentication = (payload: AuthenticationResponseJSONV2) =>
  AxiosPostTE("/webauthn/v2/verify-authentication", payload, {
    headers: {
      "x-heritage-action": "login",
    },
  });
/**
 * Check if the received json is a {@link RegistrationResponseJSON}
 * @param json
 */
const decodeRegistrationOptionResponse = (
  json: unknown,
): TE.TaskEither<PasskeyNetworkError, CredentialCreationOptions> =>
  F.pipe(
    CredentialCreationOptions.decode(json),
    TE.fromEither,
    TE.mapLeft((errors) => ({
      kind: "network",
      message: `${PathReporter.report(E.left(errors)).join("")}`,
    })),
  );

const decodeAuthenticationOptionResponse = (
  json: unknown,
): TE.TaskEither<PasskeyNetworkError, PublicKeyCredentialRequestOptions> =>
  F.pipe(
    PublicKeyCredentialRequestOptions.decode(json),
    TE.fromEither,
    TE.mapLeft((errors) => ({
      kind: "network",
      message: `${PathReporter.report(E.left(errors)).join("")}`,
    })),
  );

/**
 * Enhance the registration options with the platform and device information that we use to add metadata to the registered passkey
 * @param registrationOptions
 */
const enhanceRegistrationOptions = (
  registrationOptions: RegistrationResponseJSON,
): HRegistrationResponseJSON => {
  return {
    ...registrationOptions,
    createdFrom: "web",
    createdPlatform: getPlatformFromUserAgent(),
    createdDevice: getCreatedDevice(),
  };
};

/**
 * Request the registration option and decode the received json as {@link CredentialCreationOptions}
 */
export const requestRegistrationOptions = (): TE.TaskEither<
  PasskeyErrors,
  CredentialCreationOptions
> =>
  F.pipe(
    getRegistrationOptions(),
    TE.map((x) => x.data),
    TE.chainW(decodeRegistrationOptionResponse),
  );

/**
 * Verify the registration options, adding metadata to the registration response
 * @param registrationOptions
 */
export const verifyRegistration = (
  registrationOptions: RegistrationResponseJSON,
) =>
  F.pipe(
    registrationOptions,
    enhanceRegistrationOptions,
    postVerifyRegistrationOptions,
  );

export const requestAuthenticationOptions = (): TE.TaskEither<
  PasskeyNetworkError,
  PublicKeyCredentialRequestOptions
> =>
  F.pipe(
    getAuthenticationOptions(),
    TE.map((x) => x.data),
    TE.chainW(decodeAuthenticationOptionResponse),
  );

export const verifyAuthentication = (
  response: AuthenticationResponseJSONV2,
) => {
  return F.pipe(response, postVerifyAuthentication);
};
