import { function as F, array as A } from "fp-ts";

import React, { ReactElement, useMemo } from "react";

import {
  generatePath,
  matchPath,
  Params,
  RouteObject,
  UNSAFE_RouteContext,
} from "react-router-dom";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type FlatRoute = {
  fullPath: string;
  className: string;
};

const joinPaths = (a: string | undefined, b: string | undefined): string =>
  [a, b]
    .filter((_) => _ !== undefined)
    .join("/")
    .replace(/\/+/g, "/");

const flattenRoutes = (prefix: string, routes: RouteObject[]): FlatRoute[] =>
  F.pipe(
    routes,
    A.chain((route) => {
      const fullPath = joinPaths(prefix, route.path);
      const element = route.element;

      // get the react class name of the element that renders the route
      // we use this name to give a name to the route
      const className =
        // try to get the name from the "key" of the element
        (element as ReactElement)?.key ??
        // or else try to get it from the "displayName" of the element
        (element as ReactJSXElement)?.type?.name ??
        "";

      const thisRoute =
        fullPath.length > 0 && className !== undefined
          ? [{ fullPath, className }]
          : [];

      const children =
        route.children !== undefined
          ? flattenRoutes(fullPath, route.children)
          : [];
      return [...thisRoute, ...children];
    }),
  );

/**
 * Whether the path matches a route.
 *
 * A path like "/nextgen/my-portfolio/9ce1cc79-dcf1-4ffa-8c59-1ac9f6c2149c"
 * A route like "/nextgen/my-portfolio/:investorId"
 */
export const matchPathToRoute = (path: string, route: string) => {
  // Match empty routes.
  if (!path && !route) return true;

  return !!matchPath(route, path);
};

/**
 * Provides facilities to find routes based on the class name associated to
 * them. This is useful when we want to lookup a route to generate a link
 * (e.g. using generatePath).
 *
 * The resolver looks up the route based on the class name of the element
 * associated to it since it's the only identifier we have for a route.
 *
 * The type parameter T can be used to specify the available class names.
 */
export const useRoutesResolver = <T extends string = string>() => {
  const ctx = React.useContext(UNSAFE_RouteContext);

  const routes = F.pipe(
    ctx.matches,
    A.map(({ route }) => route),
  );

  const flattened = useMemo(() => flattenRoutes("", routes), [routes]);

  const getPathOfRoute = (className: T) =>
    flattened.find((_) => _.className === className)?.fullPath;

  const generatePathForRoute = (className: T, params?: Params<string>) =>
    generatePath(getPathOfRoute(className) ?? "#", params);

  const getRouteForPath = (path: string) =>
    flattened.find((_) => matchPathToRoute(path, _.fullPath))?.className;

  return {
    getPathOfRoute,
    generatePathForRoute,
    getRouteForPath,
  };
};
