import { useParams } from "react-router-dom";

import { useLayoutContext } from "../routes/LayoutRoute/LayoutRoute";
import { LayoutRouteQuery } from "../generated/urql";
import { useMemo } from "react";

/**
 * Looks up the investor ID from the URL parameters and returns the corresponding
 * investor object associated to the user.
 */
export const useInvestor = ():
  | LayoutRouteQuery["authUser"]["investors"][number]["investor"]
  | undefined => {
  const { investorId } = useParams();
  const { authUser } = useLayoutContext();

  const investors = useMemo(
    () => authUser?.investors?.map((_) => _.investor) ?? [],
    [authUser],
  );

  const maybeInvestor = useMemo(
    () => investors.find((_) => _.id === investorId),
    [investors, investorId],
  );

  return maybeInvestor;
};
