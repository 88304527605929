import React, { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { UserPasswordFormInput } from "../../../generated/urql";
import { UserPasswordFormInputSchema } from "../../../generated/validation-schema";
import { Alert } from "@mui/material";
import { match, P } from "ts-pattern";
import { UseMutationResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { AxiosResponse } from "axios";
import { PasswordConstraints } from "../../commons/PasswordConstraints";

function formatErrorMessage(message: string | undefined): string | undefined {
  return match(message)
    .with("TOO_SHORT", () => "The password is too short")
    .with("INVALID", () => "The password must respects the constraints")
    .with("INCORRECT", () => "Wrong password")
    .with(P.string, (e) => e)
    .otherwise(() => undefined);
}

type Props = UseMutationResult<
  AxiosResponse,
  unknown,
  UserPasswordFormInput
> & {
  firstChange?: boolean;
  onSuccess?: () => void;
};

// TODO: add password visibility toggle
// TODO: add password strength meter
// TODO: add password reset link
// TODO: reset form on successful submit
export const PasswordForm: React.FC<Props> = ({
  mutate,
  isSuccess,
  data,
  firstChange,
  onSuccess,
}) => {
  // Create the form schema by extending the graphql schema with the password
  // confirmation field
  const schema = UserPasswordFormInputSchema()
    .extend({
      confirmPassword: z.string(),
    })
    .superRefine(({ currentPassword, confirmPassword, newPassword }, ctx) => {
      if (currentPassword === newPassword) {
        ctx.addIssue({
          path: ["newPassword"],
          code: "custom",
          message: "New password should be different from the current password",
        });
      }

      if (confirmPassword !== newPassword) {
        ctx.addIssue({
          path: ["confirmPassword"],
          code: "custom",
          message: "The new password and confirmation do not match",
        });
      }
    });

  type FormData = z.infer<typeof schema>;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess && data.status === 200) {
      enqueueSnackbar("Password changed successfully", {
        variant: "success",
      });

      onSuccess?.();
    } else if (isSuccess && data.status !== 200) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  }, [isSuccess, data, enqueueSnackbar, onSuccess]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
  } = useForm<FormData>({
    mode: "all",
    resolver: zodResolver(schema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const watchedPassword = useWatch({
    control,
    name: "newPassword",
    defaultValue: "",
  });

  return (
    <>
      {firstChange ? (
        <Box mb={2}>
          <Alert severity="warning">
            Your current password is temporary. Please choose a new password to
            ensure the security of your account.
          </Alert>
        </Box>
      ) : null}

      <Typography variant="h4" sx={{ marginTop: "2ch", m: 1 }}>
        Change Password
      </Typography>

      <Box
        component="form"
        sx={{
          m: 1,
          p: 2,
          boxShadow: "0px 0px 20px rgb(31 30 30 / 10%)",
          borderRadius: "4px",
          "& .MuiTextField-root": { m: 1 },
        }}
        noValidate
      >
        <Box>
          <Controller
            name={"currentPassword"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"Current password"}
                type="password"
                sx={{ width: "30ch" }}
                autoComplete="current-password"
                helperText={formatErrorMessage(error?.message)}
                disabled={isSubmitting}
              />
            )}
          />
        </Box>
        <Box display={"flex"} sx={{ direction: "row" }}>
          <Controller
            name={"newPassword"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"New password"}
                type="password"
                sx={{ width: "30ch" }}
                autoComplete="new-password"
                helperText={formatErrorMessage(error?.message)}
                disabled={isSubmitting}
              />
            )}
          />
          <Controller
            name={"confirmPassword"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                error={error !== undefined}
                label={"Confirm new password"}
                type="password"
                autoComplete="new-password"
                sx={{ width: "30ch" }}
                helperText={formatErrorMessage(error?.message)}
                disabled={isSubmitting}
              />
            )}
          />
        </Box>

        <Box sx={{ width: 400, px: 1 }}>
          <PasswordConstraints password={watchedPassword} />
        </Box>

        <LoadingButton
          variant="outlined"
          onClick={handleSubmit((data) => mutate(data))}
          disabled={!isDirty || !isValid || isSubmitting}
          loading={isSubmitting}
          sx={{ m: 1 }}
        >
          Change Password
        </LoadingButton>
      </Box>
    </>
  );
};
