import * as E from "fp-ts/Either";
import * as F from "fp-ts/function";
import * as t from "io-ts";
import { Errors } from "io-ts";
import { PathReporter } from "io-ts/PathReporter";

export type GenericError = {
  kind: "generic";
} & Error;

const ErrorT = t.type({
  message: t.string,
});

const ErrorP = t.partial({
  fileName: t.string,
  lineNumber: t.string,
  stack: t.string,
});

const Error = t.intersection([ErrorT, ErrorP]);

type Error = t.TypeOf<typeof Error>;

const parseStringError = (e: unknown): E.Either<Errors, GenericError> => {
  if (typeof e === "string") {
    return E.right({
      kind: "generic",
      message: e,
    });
  }
  return E.left([]);
};
const parseJavaScriptError = (e: unknown) =>
  F.pipe(
    Error.decode(e),
    E.map(
      (x): GenericError => ({
        kind: "generic",
        message: x.message,
        fileName: x.fileName,
        lineNumber: x.lineNumber,
        stack: x.stack,
      }),
    ),
  );

export const parseUnknownError = (e: unknown): GenericError =>
  F.pipe(
    e,
    parseStringError,
    E.alt(() => parseJavaScriptError(e)),
    E.getOrElse((x) => ({
      kind: "generic",
      message: `Generic error. Cannot parse the error: ${PathReporter.report(
        E.left(x),
      ).join("")}`,
    })),
  );
