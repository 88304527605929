import gql from "graphql-tag";
import * as React from "react";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

/** An Admin user */
export type Admin = {
  readonly __typename: "Admin";
  readonly email: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** Status where an allocation can be */
export enum AllocationsStatus {
  AllocationRequested = "ALLOCATION_REQUESTED",
  AwaitingContractSignature = "AWAITING_CONTRACT_SIGNATURE",
  ContractSigned = "CONTRACT_SIGNED",
  Enabled = "ENABLED",
  Refused = "REFUSED",
}

/** An investment asset */
export type Asset = {
  readonly __typename: "Asset";
  readonly closingDate: Scalars["String"]["output"];
  readonly committedCapitalCalledPercent: Scalars["Float"]["output"];
  readonly coverImageUrl?: Maybe<Scalars["String"]["output"]>;
  readonly currency?: Maybe<Scalars["String"]["output"]>;
  readonly description?: Maybe<Scalars["String"]["output"]>;
  readonly diversification: DiversificationReport;
  readonly firstCapitalCallDate?: Maybe<Scalars["String"]["output"]>;
  readonly gpCommitment?: Maybe<Scalars["Float"]["output"]>;
  readonly id: Scalars["String"]["output"];
  readonly investmentPeriod?: Maybe<Scalars["String"]["output"]>;
  readonly isDemo: Scalars["Boolean"]["output"];
  readonly jcurveYears: ReadonlyArray<JCurveYear>;
  readonly lifetime?: Maybe<Scalars["Float"]["output"]>;
  readonly media: ReadonlyArray<Media>;
  readonly minDrawdownYear1Percent: Scalars["Float"]["output"];
  readonly minimumParticipationUsd: Scalars["Float"]["output"];
  readonly moicBase: Scalars["Float"]["output"];
  readonly moicHigh: Scalars["Float"]["output"];
  readonly moicLow: Scalars["Float"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly oneLiner?: Maybe<Scalars["String"]["output"]>;
  readonly sizeAmountUsd?: Maybe<Scalars["Float"]["output"]>;
  readonly status: Scalars["String"]["output"];
  readonly targetGrossIrr?: Maybe<Scalars["Float"]["output"]>;
  readonly targetGrossMom?: Maybe<Scalars["Float"]["output"]>;
  readonly targetSize?: Maybe<Scalars["Float"]["output"]>;
};

/** An Authenticator */
export type Authenticator = {
  readonly __typename: "Authenticator";
  /** The date when the authenticator was created */
  readonly createdAt?: Maybe<Scalars["String"]["output"]>;
  /** The device that created the authenticator (eg. iPhone 11, Samsung Galaxy S10) */
  readonly createdDevice?: Maybe<Scalars["String"]["output"]>;
  /** The heritage client that created the authenticator (eg. web, mobile app) */
  readonly createdFromClient: AuthenticatorCreatedFromClient;
  /** The platform that created the authenticator (eg. iOS, Android, Windows) */
  readonly createdPlatform: AuthenticatorCreatedPlatform;
  /** @deprecated Use credentialId instead */
  readonly credentialId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  /** The date when the authenticator was last used */
  readonly lastUsed?: Maybe<Scalars["String"]["output"]>;
};

/** The heritage client that created the authenticator (eg. web, mobile app) */
export enum AuthenticatorCreatedFromClient {
  Mobile = "MOBILE",
  Others = "OTHERS",
  Web = "WEB",
}

/** The platform that created the authenticator (eg. iOS, Android, Windows) */
export enum AuthenticatorCreatedPlatform {
  Android = "ANDROID",
  Ios = "IOS",
  Linux = "LINUX",
  Mac = "MAC",
  Others = "OTHERS",
  Windows = "WINDOWS",
}

/** Status of a bank account */
export enum BankAccountStatus {
  Approved = "APPROVED",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Pending = "PENDING",
}

/**
 * Registration form data for business investors
 *
 * Note that this type is no used by a GraphQL mutation, but it's here just to get
 * the Zod schema generated automatically
 */
export type BusinessInvestorRegistrationFormInput = {
  readonly businessName: Scalars["String"]["input"];
  readonly countryOfIncorporation: Scalars["String"]["input"];
};

/** A Capital Call document */
export type CapitalCallDocument = {
  readonly __typename: "CapitalCallDocument";
  readonly link: Scalars["String"]["output"];
  readonly read: Scalars["Boolean"]["output"];
};

/** A CapitalCall */
export type CapitalCallOperation = {
  readonly __typename: "CapitalCallOperation";
  /** @deprecated Refer to each transaction instead */
  readonly currency: Scalars["String"]["output"];
  readonly deadlineDate?: Maybe<Scalars["String"]["output"]>;
  readonly document: CapitalCallDocument;
  /** @deprecated This should not be used in the frontend */
  readonly finalized: Scalars["Boolean"]["output"];
  readonly transactions: ReadonlyArray<CapitalCallTransaction>;
  readonly uuid: Scalars["String"]["output"];
};

/** A CapitalCall */
export type CapitalCallOperationDocumentArgs = {
  investorId: Scalars["String"]["input"];
};

/** A transaction relative to a CapitalCall */
export type CapitalCallTransaction = {
  readonly __typename: "CapitalCallTransaction";
  readonly allocation: EnabledAllocation;
  /** @deprecated Use paidAmount instead */
  readonly amountUsd: Scalars["Float"]["output"];
  /** @deprecated Use allocation asset instead */
  readonly asset: Asset;
  /** @deprecated Use asset instead */
  readonly assets: ReadonlyArray<Asset>;
  readonly calledAmount: Scalars["Float"]["output"];
  /** @deprecated Use allocation currency instead */
  readonly currency: Scalars["String"]["output"];
  /** @deprecated expectedAmount minus amount, rounded to cents */
  readonly dueUsd: Scalars["Float"]["output"];
  /** @deprecated Use calledAmount instead */
  readonly expectedAmountUsd: Scalars["Float"]["output"];
  readonly isPaid: Scalars["Boolean"]["output"];
  readonly isPendingFx: Scalars["Boolean"]["output"];
  readonly paidAmount: Scalars["Float"]["output"];
  /** @deprecated Use amountUsd instead */
  readonly paidUsd: Scalars["Float"]["output"];
  /** @deprecated Not used anymore */
  readonly residualUsd: Scalars["Float"]["output"];
  readonly uuid: Scalars["String"]["output"];
};

/** The global app configuration, non user specific */
export type Configuration = {
  readonly __typename: "Configuration";
  readonly defaultQuarter: Scalars["String"]["output"];
};

/** A Contract */
export type Contract = {
  readonly __typename: "Contract";
  readonly active: Scalars["Boolean"]["output"];
  readonly approvedAt?: Maybe<Scalars["String"]["output"]>;
  readonly canBeDownloaded: Scalars["Boolean"]["output"];
  readonly hasWatermark: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly media: ReadonlyArray<Media>;
  readonly name: Scalars["String"]["output"];
  readonly notes?: Maybe<Scalars["String"]["output"]>;
  readonly type: Scalars["String"]["output"];
};

/** Route (page) of the dashboard */
export enum DashboardRoute {
  Assets = "ASSETS",
  Portfolio = "PORTFOLIO",
}

/** The error of the delete authenticator operation */
export type DeleteAuthenticatorError = {
  readonly __typename: "DeleteAuthenticatorError";
  readonly message: Scalars["String"]["output"];
};

/** The result of the delete authenticator operation */
export type DeleteAuthenticatorResult =
  | DeleteAuthenticatorError
  | DeleteAuthenticatorSuccess;

/** The result of the delete authenticator operation */
export type DeleteAuthenticatorSuccess = {
  readonly __typename: "DeleteAuthenticatorSuccess";
  readonly success: Scalars["Boolean"]["output"];
};

/** The error of a generic delete operation */
export type DeleteError = {
  readonly __typename: "DeleteError";
  readonly message: Scalars["String"]["output"];
};

/** The result of a generic delete operation */
export type DeleteResult = DeleteError | DeleteSuccess;

/** The result of a generic delete operation */
export type DeleteSuccess = {
  readonly __typename: "DeleteSuccess";
  readonly success: Scalars["Boolean"]["output"];
};

/** A Distribution document */
export type DistributionDocument = {
  readonly __typename: "DistributionDocument";
  readonly link: Scalars["String"]["output"];
  readonly read: Scalars["Boolean"]["output"];
};

/** A Distribution */
export type DistributionOperation = {
  readonly __typename: "DistributionOperation";
  readonly document: DistributionDocument;
  readonly investorUuid: Scalars["String"]["output"];
  readonly transactions: ReadonlyArray<DistributionTransaction>;
  readonly uuid: Scalars["String"]["output"];
  readonly valueDate: Scalars["String"]["output"];
};

/** A transaction relative to a Distribution */
export type DistributionTransaction = {
  readonly __typename: "DistributionTransaction";
  readonly allocation: EnabledAllocation;
  readonly amount: Scalars["Float"]["output"];
  readonly isPaid: Scalars["Boolean"]["output"];
  readonly uuid: Scalars["String"]["output"];
};

/** A report about diversification over several classes (strategies, industries, etc.) */
export type DiversificationReport = {
  readonly __typename: "DiversificationReport";
  readonly geographies: ReadonlyArray<DiversificationReportCategory>;
  readonly industries: ReadonlyArray<DiversificationReportCategory>;
  readonly strategies: ReadonlyArray<DiversificationReportCategory>;
};

/**
 * An item of a diversification report, provides the amount of diversified capital
 * for a specific category of a diversification class.
 */
export type DiversificationReportCategory = {
  readonly __typename: "DiversificationReportCategory";
  readonly amount: Scalars["Float"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** A Document */
export type Document = {
  readonly __typename: "Document";
  readonly approvedAt?: Maybe<Scalars["String"]["output"]>;
  readonly assets: ReadonlyArray<Asset>;
  readonly canBeDownloaded: Scalars["Boolean"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly media: ReadonlyArray<Media>;
  readonly name: Scalars["String"]["output"];
  readonly type: DocumentType;
};

/** Types of a Document */
export enum DocumentType {
  Overview = "OVERVIEW",
  Platform = "PLATFORM",
}

/** Information required to retrieve a document */
export type DownloadableDocument = {
  readonly __typename: "DownloadableDocument";
  readonly status: DownloadableDocumentStatus;
  readonly url: Scalars["String"]["output"];
};

/** The availability status of a downloadable document */
export enum DownloadableDocumentStatus {
  Error = "ERROR",
  Pending = "PENDING",
  Ready = "READY",
  Unhauthorized = "UNHAUTHORIZED",
}

/** An Allocation in the 'Enabled' status */
export type EnabledAllocation = IAllocation & {
  readonly __typename: "EnabledAllocation";
  readonly allocationDate: Scalars["String"]["output"];
  readonly amountUsd: Scalars["Float"]["output"];
  readonly asset: Asset;
  readonly currency: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly vehicle: InvestmentVehicle;
};

/** All possible features that a user can have */
export enum FeatureFlag {
  /** Enable the bank accounts section in the frontend */
  EnableBankAccountsSection = "ENABLE_BANK_ACCOUNTS_SECTION",
  /**
   * This feature flag is going to enable the Capital Call
   * section inside the VDR.
   */
  EnableCapitalCallVdr = "ENABLE_CAPITAL_CALL_VDR",
  /** Shows switches to enable experimental features */
  EnableDeveloperMode = "ENABLE_DEVELOPER_MODE",
  /**
   * This feature flag is going to enable the Distribution
   * section inside the VDR.
   */
  EnableDistributionVdr = "ENABLE_DISTRIBUTION_VDR",
  /** Enables the early adopter mode */
  EnableEarlyAdopterMode = "ENABLE_EARLY_ADOPTER_MODE",
  /** Enables the V2 version of NAV calculations */
  EnableNavV2 = "ENABLE_NAV_V2",
}

/** Results returned by the server when submitting a form */
export type FormSubmitResult = {
  readonly __typename: "FormSubmitResult";
  readonly message?: Maybe<Scalars["String"]["output"]>;
  readonly success: Scalars["Boolean"]["output"];
  readonly validationErrors?: Maybe<ReadonlyArray<ValidationError>>;
};

/** A Fund Manager */
export type FundManager = {
  readonly __typename: "FundManager";
  readonly id: Scalars["ID"]["output"];
};

/** Fields shared by all Allocation types */
export type IAllocation = {
  readonly amountUsd: Scalars["Float"]["output"];
  readonly asset: Asset;
  readonly currency: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
};

/** An investment vehicle */
export type InvestmentVehicle = {
  readonly __typename: "InvestmentVehicle";
  readonly id: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** Root type of all investor related data */
export type Investor = {
  readonly __typename: "Investor";
  readonly address?: Maybe<Scalars["String"]["output"]>;
  /**
   * Admin approval status
   *
   * FIXME: This is probably not used effectively inside our application,
   * we should investigate and in case remove it.
   */
  readonly adminApprovedAt?: Maybe<Scalars["String"]["output"]>;
  /**
   * Admin rejection status
   *
   * FIXME: This is probably not used effectively inside our application,
   * we should investigate and in case remove it.
   */
  readonly adminRejectedAt?: Maybe<Scalars["String"]["output"]>;
  /** FM approval date. */
  readonly approvedAt?: Maybe<Scalars["String"]["output"]>;
  /** The bank accounts of the investor */
  readonly bankAccounts?: Maybe<ReadonlyArray<InvestorBankAccount>>;
  readonly businessName: Scalars["String"]["output"];
  /** The capital calls issued to the investor */
  readonly capitalCalls: ReadonlyArray<CapitalCallOperation>;
  /**
   * The capital calls issued to the investor (financial stack)
   * @deprecated Use capitalCalls
   */
  readonly capitalCallsV2: ReadonlyArray<CapitalCallOperation>;
  readonly city?: Maybe<Scalars["String"]["output"]>;
  /** Returns a list of assets that are not available anymore for investment. */
  readonly closedAssets: ReadonlyArray<Asset>;
  /** The contracts associated to the investor */
  readonly contracts: ReadonlyArray<Contract>;
  readonly country?: Maybe<Scalars["String"]["output"]>;
  readonly countryOfResidence?: Maybe<Scalars["String"]["output"]>;
  /**
   * The default route of the dashboard to land the user when this investor is selected
   * This is used to instruct the frontend to land the user on the assets page when
   * the investor has no portfolio yet
   */
  readonly defaultRoute: DashboardRoute;
  /**
   * If defined, it means that the frontend should display data from this demo
   * investor ID, instead of the original one
   * @deprecated This functionality has been removed
   */
  readonly demoId?: Maybe<Scalars["ID"]["output"]>;
  /** The distributions issued to the investor */
  readonly distributions: ReadonlyArray<DistributionOperation>;
  /** The documents associated to the investor */
  readonly documents: ReadonlyArray<Document>;
  /** All the allocations this investor has made that have not yet been processed */
  readonly enabledAllocations: ReadonlyArray<EnabledAllocation>;
  /**
   * The assets this investor is allowed to invest in
   * FIXME: how is this different from openAssets?
   */
  readonly enabledAssets: ReadonlyArray<Asset>;
  /**
   * First allocation made by the investor.
   * N.B. This includes cancelled allocations too.
   */
  readonly firstAllocation?: Maybe<EnabledAllocation>;
  readonly id: Scalars["String"]["output"];
  /** The vehicles the investor has invested in */
  readonly investmentVehicles: ReadonlyArray<InvestmentVehicle>;
  readonly nationality?: Maybe<Scalars["String"]["output"]>;
  /**
   * Returns a list of assets that are not yet in the portfolio and that the
   * investor is allowed to invest in.
   */
  readonly openAssets: ReadonlyArray<Asset>;
  /** All the allocations this investor has made that have not yet been processed */
  readonly pendingAllocations: ReadonlyArray<PendingAllocation>;
  readonly phone?: Maybe<Scalars["String"]["output"]>;
  /**
   * Gets a PDF document with the situation of the investor's portfolio for the
   * provided quarter.
   */
  readonly portfolioPdfForQuarter: DownloadableDocument;
  /** Returns the portfolio situation for the investor for the provided cutoff dates */
  readonly portfolioSituation: ReadonlyArray<PortfolioSituation>;
  /**
   * Returns the portfolio situation for the investor for the provided cutoff dates (financial stack)
   * @deprecated use portfolioSituation
   */
  readonly portfolioSituationV2: ReadonlyArray<PortfolioSituation>;
  /** FM rejection date. */
  readonly rejectedAt?: Maybe<Scalars["String"]["output"]>;
  readonly role: Scalars["String"]["output"];
  readonly type: InvestorType;
  readonly vat?: Maybe<Scalars["String"]["output"]>;
};

/** Root type of all investor related data */
export type InvestorContractsArgs = {
  assetId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Root type of all investor related data */
export type InvestorDocumentsArgs = {
  assetId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Root type of all investor related data */
export type InvestorPortfolioPdfForQuarterArgs = {
  quarter: Scalars["String"]["input"];
};

/** Root type of all investor related data */
export type InvestorPortfolioSituationArgs = {
  cutoffs: ReadonlyArray<Scalars["String"]["input"]>;
  flags?: InputMaybe<ReadonlyArray<FeatureFlag>>;
};

/** Root type of all investor related data */
export type InvestorPortfolioSituationV2Args = {
  cutoffs: ReadonlyArray<Scalars["String"]["input"]>;
};

/** Input payload when submitting the user account form */
export type InvestorAccountFormInput = {
  readonly address?: InputMaybe<Scalars["String"]["input"]>;
  readonly businessName: Scalars["String"]["input"];
  readonly city?: InputMaybe<Scalars["String"]["input"]>;
  readonly country?: InputMaybe<Scalars["String"]["input"]>;
  readonly countryOfResidence?: InputMaybe<Scalars["String"]["input"]>;
  readonly nationality?: InputMaybe<Scalars["String"]["input"]>;
  readonly phone?: InputMaybe<Scalars["String"]["input"]>;
  readonly vat?: InputMaybe<Scalars["String"]["input"]>;
};

/** A bank account of an investor */
export type InvestorBankAccount = {
  readonly __typename: "InvestorBankAccount";
  readonly accountHolder: Scalars["String"]["output"];
  readonly accountNumber?: Maybe<Scalars["String"]["output"]>;
  readonly activeFrom?: Maybe<Scalars["String"]["output"]>;
  readonly bankAddress: Scalars["String"]["output"];
  readonly bankName: Scalars["String"]["output"];
  readonly createdAt: Scalars["String"]["output"];
  readonly createdByUser?: Maybe<User>;
  readonly currency: Scalars["String"]["output"];
  readonly iban?: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ID"]["output"];
  readonly status: BankAccountStatus;
  readonly swift?: Maybe<Scalars["String"]["output"]>;
};

/** Input payload when submitting the creation of a new bank account */
export type InvestorBankAccountFormInput = {
  readonly accountHolder: Scalars["String"]["input"];
  readonly accountNumber?: InputMaybe<Scalars["String"]["input"]>;
  readonly bankAddress: Scalars["String"]["input"];
  readonly bankName: Scalars["String"]["input"];
  readonly currency: Scalars["String"]["input"];
  readonly iban?: InputMaybe<Scalars["String"]["input"]>;
  readonly investorId: Scalars["String"]["input"];
  readonly swift?: InputMaybe<Scalars["String"]["input"]>;
  readonly vehicleId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input payload when submitting the create/update allocation form */
export type InvestorCreateOrUpdateAllocationFormInput = {
  readonly amountUsd: Scalars["Float"]["input"];
  readonly assetId: Scalars["String"]["input"];
  readonly investorId: Scalars["String"]["input"];
  readonly pendingAllocationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Result returned when submitting the create allocation form */
export type InvestorCreateOrUpdateAllocationFormResult = {
  readonly __typename: "InvestorCreateOrUpdateAllocationFormResult";
  readonly pendingAllocation?: Maybe<PendingAllocation>;
  readonly result: FormSubmitResult;
};

/**
 * Registration form data
 *
 * Note that the input contains both private and business investors, but only one
 * of them should be defined. Unfortunately we cannot use discriminated unions as
 * it is not possible to generate a Zod schema with literal types.
 *
 * Note that this type is no used by a GraphQL mutation, but it's here just to get
 * the Zod schema generated automatically
 */
export type InvestorRegistrationFormInput = {
  readonly business?: InputMaybe<BusinessInvestorRegistrationFormInput>;
  readonly private?: InputMaybe<PrivateInvestorRegistrationFormInput>;
  readonly user: UserInvestorRegistrationFormInput;
};

/** All possible type of an Investor */
export enum InvestorType {
  Employee = "EMPLOYEE",
  Sales = "SALES",
  Standard = "STANDARD",
}

/** Type of all investor related data inside the VDR */
export type InvestorVdr = {
  readonly __typename: "InvestorVdr";
  readonly businessName: Scalars["String"]["output"];
  /** The documents associated to the investor */
  readonly documents: ReadonlyArray<Document>;
  readonly id: Scalars["ID"]["output"];
};

/** Type of all investor related data inside the VDR */
export type InvestorVdrDocumentsArgs = {
  assetId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Implied drawdowns and distributions for a specific Asset */
export type JCurveYear = {
  readonly __typename: "JCurveYear";
  readonly distributionPercent: Scalars["Float"]["output"];
  readonly drawdownPercent: Scalars["Float"]["output"];
  readonly year: Scalars["Int"]["output"];
};

/** A media object */
export type Media = {
  readonly __typename: "Media";
  readonly collection: Scalars["String"]["output"];
  readonly fileName: Scalars["String"]["output"];
  readonly hasThumbnail: Scalars["Boolean"]["output"];
  readonly id: Scalars["String"]["output"];
  readonly mimeType?: Maybe<Scalars["String"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly publicMediaId: Scalars["String"]["output"];
};

/** Mutations */
export type Mutation = {
  readonly __typename: "Mutation";
  /**
   * Request the creation of a PDF document with the situation of the investor's
   * portfolio for the provided quarter.
   */
  readonly createPortfolioPdfForQuarter: Scalars["Boolean"]["output"];
  /** Delete a registered authenticator */
  readonly deleteAuthenticator: DeleteAuthenticatorResult;
  /** Delete a registered bank account */
  readonly deleteInvestorBankAccount: DeleteResult;
  /** Updates the investor account */
  readonly submitInvestorAccountForm: FormSubmitResult;
  /** Add an investor bank account */
  readonly submitInvestorBankAccountForm: FormSubmitResult;
  /** Creates a new Allocation or update the existing one */
  readonly submitInvestorCreateOrUpdateAllocationForm: InvestorCreateOrUpdateAllocationFormResult;
  /** Updates the user profile */
  readonly submitUserAccountForm: FormSubmitResult;
  /** Updates the user password */
  readonly submitUserPasswordForm: FormSubmitResult;
};

/** Mutations */
export type MutationCreatePortfolioPdfForQuarterArgs = {
  investorId: Scalars["String"]["input"];
  quarter: Scalars["String"]["input"];
};

/** Mutations */
export type MutationDeleteAuthenticatorArgs = {
  id: Scalars["ID"]["input"];
};

/** Mutations */
export type MutationDeleteInvestorBankAccountArgs = {
  id: Scalars["ID"]["input"];
};

/** Mutations */
export type MutationSubmitInvestorAccountFormArgs = {
  input: InvestorAccountFormInput;
  investorId: Scalars["String"]["input"];
};

/** Mutations */
export type MutationSubmitInvestorBankAccountFormArgs = {
  input: InvestorBankAccountFormInput;
};

/** Mutations */
export type MutationSubmitInvestorCreateOrUpdateAllocationFormArgs = {
  input: InvestorCreateOrUpdateAllocationFormInput;
};

/** Mutations */
export type MutationSubmitUserAccountFormArgs = {
  input: UserAccountFormInput;
};

/** Mutations */
export type MutationSubmitUserPasswordFormArgs = {
  input: UserPasswordFormInput;
};

/**
 * An Allocation that is pending approval
 * Status: 'Allocation Requested','Awaiting contract signature','Contract Signed'
 */
export type PendingAllocation = IAllocation & {
  readonly __typename: "PendingAllocation";
  readonly amountUsd: Scalars["Float"]["output"];
  readonly asset: Asset;
  readonly currency: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly status: AllocationsStatus;
};

/** The situation of a portfolio */
export type PortfolioSituation = {
  readonly __typename: "PortfolioSituation";
  readonly annotatedByAllocation: ReadonlyArray<PortfolioSituationAnnotation>;
  readonly annotatedTotals: PortfolioSituationAnnotation;
  readonly byAllocation: ReadonlyArray<PortfolioSituationForAllocation>;
  readonly companies: ReadonlyArray<PortfolioSituationCompany>;
  readonly cutoffDate: Scalars["String"]["output"];
  readonly diversificationByAllocationAmount: DiversificationReport;
  /** @deprecated Use diversificationByAllocationAmount instead */
  readonly diversificationByPaidIn: DiversificationReport;
  readonly pendingAllocations: ReadonlyArray<PendingAllocation>;
  readonly pendingTotals: PortfolioSituationPendingTotals;
  readonly totals: PortfolioSituationTotals;
};

/** Extra annotations for a specific Allocation or Totals */
export type PortfolioSituationAnnotation = {
  readonly __typename: "PortfolioSituationAnnotation";
  readonly mom?: Maybe<Scalars["String"]["output"]>;
  readonly nav?: Maybe<Scalars["String"]["output"]>;
  readonly profit?: Maybe<Scalars["String"]["output"]>;
  readonly trailJson_UNSTABLE?: Maybe<Scalars["String"]["output"]>;
};

/** A Company that is part of a portfolio */
export type PortfolioSituationCompany = {
  readonly __typename: "PortfolioSituationCompany";
  readonly amountUsd?: Maybe<Scalars["Float"]["output"]>;
  readonly asset: Asset;
  readonly id: Scalars["ID"]["output"];
  readonly mom?: Maybe<Scalars["Float"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly navUsd?: Maybe<Scalars["Float"]["output"]>;
  readonly percentage?: Maybe<Scalars["Float"]["output"]>;
  readonly region?: Maybe<Scalars["String"]["output"]>;
  readonly sector?: Maybe<Scalars["String"]["output"]>;
  readonly website?: Maybe<Scalars["String"]["output"]>;
};

/** The portfolio situation relative to a specific Allocation */
export type PortfolioSituationForAllocation = {
  readonly __typename: "PortfolioSituationForAllocation";
  readonly allocation: EnabledAllocation;
  readonly distributedUsd: Scalars["Float"]["output"];
  readonly dpi: Scalars["Float"]["output"];
  readonly irr: Scalars["Float"]["output"];
  readonly mom: Scalars["Float"]["output"];
  readonly navUsd: Scalars["Float"]["output"];
  readonly paidInUsd: Scalars["Float"]["output"];
  readonly profitUsd: Scalars["Float"]["output"];
  readonly sourcePaidInUsd: Scalars["Float"]["output"];
};

/** Consolidated portfolio situation for pending allocations */
export type PortfolioSituationPendingTotals = {
  readonly __typename: "PortfolioSituationPendingTotals";
  readonly allocationAmountUsd: Scalars["Float"]["output"];
};

/** Consolidated portfolio situation */
export type PortfolioSituationTotals = {
  readonly __typename: "PortfolioSituationTotals";
  readonly allocationAmountUsd: Scalars["Float"]["output"];
  readonly distributedUsd: Scalars["Float"]["output"];
  readonly dpi: Scalars["Float"]["output"];
  readonly irr: Scalars["Float"]["output"];
  readonly mom: Scalars["Float"]["output"];
  readonly navUsd: Scalars["Float"]["output"];
  readonly paidInUsd: Scalars["Float"]["output"];
  readonly profitUsd: Scalars["Float"]["output"];
};

/**
 * Registration form data for private investors
 *
 * Note that this type is no used by a GraphQL mutation, but it's here just to get
 * the Zod schema generated automatically
 */
export type PrivateInvestorRegistrationFormInput = {
  readonly countryOfResidence: Scalars["String"]["input"];
  readonly role: Scalars["String"]["input"];
};

/** Queries */
export type Query = {
  readonly __typename: "Query";
  readonly authFundManager: FundManager;
  readonly authUser: User;
  readonly configuration: Configuration;
  /** Returns data about an investor */
  readonly investor: Investor;
  /** Returns the VDR data about an investor */
  readonly investorVdr: InvestorVdr;
  readonly status: Status;
};

/** Queries */
export type QueryInvestorArgs = {
  investorId: Scalars["String"]["input"];
};

/** Queries */
export type QueryInvestorVdrArgs = {
  investorId: Scalars["String"]["input"];
};

/** Reports system information and statistics. */
export type Status = {
  readonly __typename: "Status";
  readonly system: SystemStatus;
};

/** SSE to inform clients about async changes */
export type Subscription = {
  readonly __typename: "Subscription";
  readonly system: SystemStatus;
};

/** Describe the system status */
export enum SystemStatus {
  Maintenance = "MAINTENANCE",
  UpAndRunning = "UP_AND_RUNNING",
}

/** A User */
export type User = {
  readonly __typename: "User";
  /** The authenticators that are registered for this user */
  readonly authenticators: ReadonlyArray<Authenticator>;
  /** User specific configs. */
  readonly configs: UserConfigs;
  readonly email: Scalars["String"]["output"];
  /** Feature flags that are enabled for this user */
  readonly featureFlags: ReadonlyArray<FeatureFlag>;
  readonly firstName: Scalars["String"]["output"];
  readonly id: Scalars["ID"]["output"];
  /**
   * The admin user that is impersonating this user, in case this user is an
   * impersonated user
   * @deprecated The functionality is not supported anymore
   */
  readonly impersonator?: Maybe<Admin>;
  /** The investor this user has access to */
  readonly investors: ReadonlyArray<UserInvestor>;
  readonly lastName: Scalars["String"]["output"];
  readonly passwordChangedAt?: Maybe<Scalars["String"]["output"]>;
};

/** Input payload when submitting the user account form */
export type UserAccountFormInput = {
  readonly firstName?: InputMaybe<Scalars["String"]["input"]>;
  readonly lastName?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * Custom configs for a User.
 * Usually used for temporary or non-structured data.
 */
export type UserConfigs = {
  readonly __typename: "UserConfigs";
  /** @deprecated The functionality is not supported anymore */
  readonly themeV2SwitchEnabled?: Maybe<Scalars["Boolean"]["output"]>;
};

/**
 * An investor the user is able to access, along with attributes of the relations
 * such as the roles that the user has on the investor
 */
export type UserInvestor = {
  readonly __typename: "UserInvestor";
  readonly id: Scalars["ID"]["output"];
  readonly investor: Investor;
  readonly roles: ReadonlyArray<UserInvestorRole>;
};

/**
 * Registration form data for the associated user
 *
 * Note that this type is no used by a GraphQL mutation, but it's here just to get
 * the Zod schema generated automatically
 */
export type UserInvestorRegistrationFormInput = {
  readonly email: Scalars["String"]["input"];
  readonly firstName: Scalars["String"]["input"];
  readonly lastName: Scalars["String"]["input"];
  readonly password: Scalars["String"]["input"];
};

/** All possible roles that a User can have on an Investor */
export enum UserInvestorRole {
  Collaborator = "COLLABORATOR",
  Owner = "OWNER",
}

/** Input payload when submitting the change password form */
export type UserPasswordFormInput = {
  readonly currentPassword: Scalars["String"]["input"];
  /** Reference: https://github.com/heritageholdings/iconic/blob/15504d21ef4fd556977b365f609e3645f176502a/app/ICONIC/Web/Http/Controllers/Dashboard/MeController.php#L202 */
  readonly newPassword: Scalars["String"]["input"];
};

/** A validation error for a field, returned by the server when submitting a form */
export type ValidationError = {
  readonly __typename: "ValidationError";
  readonly field: Scalars["String"]["output"];
  readonly message: Scalars["String"]["output"];
};

export type DeleteAuthenticatorMutationVariables = Exact<{
  input: Scalars["ID"]["input"];
}>;

export type DeleteAuthenticatorMutation = {
  readonly __typename: "Mutation";
  readonly deleteAuthenticator:
    | {
        readonly __typename: "DeleteAuthenticatorError";
        readonly message: string;
      }
    | {
        readonly __typename: "DeleteAuthenticatorSuccess";
        readonly success: boolean;
      };
};

export type AssetPartsFragment = {
  readonly __typename: "Asset";
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly closingDate: string;
  readonly targetSize?: number | null;
  readonly targetGrossIrr?: number | null;
  readonly targetGrossMom?: number | null;
  readonly lifetime?: number | null;
  readonly investmentPeriod?: string | null;
  readonly minimumParticipationUsd: number;
  readonly currency?: string | null;
  readonly gpCommitment?: number | null;
  readonly isDemo: boolean;
  readonly media: ReadonlyArray<{
    readonly __typename: "Media";
    readonly id: string;
    readonly publicMediaId: string;
    readonly fileName: string;
    readonly collection: string;
    readonly mimeType?: string | null;
  }>;
  readonly diversification: {
    readonly __typename: "DiversificationReport";
    readonly strategies: ReadonlyArray<{
      readonly __typename: "DiversificationReportCategory";
      readonly name: string;
      readonly amount: number;
    }>;
    readonly geographies: ReadonlyArray<{
      readonly __typename: "DiversificationReportCategory";
      readonly name: string;
      readonly amount: number;
    }>;
    readonly industries: ReadonlyArray<{
      readonly __typename: "DiversificationReportCategory";
      readonly name: string;
      readonly amount: number;
    }>;
  };
};

export type AssetRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
  assetId: Scalars["String"]["input"];
}>;

export type AssetRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly openAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly closingDate: string;
      readonly targetSize?: number | null;
      readonly targetGrossIrr?: number | null;
      readonly targetGrossMom?: number | null;
      readonly lifetime?: number | null;
      readonly investmentPeriod?: string | null;
      readonly minimumParticipationUsd: number;
      readonly currency?: string | null;
      readonly gpCommitment?: number | null;
      readonly isDemo: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly id: string;
        readonly publicMediaId: string;
        readonly fileName: string;
        readonly collection: string;
        readonly mimeType?: string | null;
      }>;
      readonly diversification: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
      };
    }>;
    readonly closedAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly closingDate: string;
      readonly targetSize?: number | null;
      readonly targetGrossIrr?: number | null;
      readonly targetGrossMom?: number | null;
      readonly lifetime?: number | null;
      readonly investmentPeriod?: string | null;
      readonly minimumParticipationUsd: number;
      readonly currency?: string | null;
      readonly gpCommitment?: number | null;
      readonly isDemo: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly id: string;
        readonly publicMediaId: string;
        readonly fileName: string;
        readonly collection: string;
        readonly mimeType?: string | null;
      }>;
      readonly diversification: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
      };
    }>;
    readonly enabledAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly closingDate: string;
      readonly targetSize?: number | null;
      readonly targetGrossIrr?: number | null;
      readonly targetGrossMom?: number | null;
      readonly lifetime?: number | null;
      readonly investmentPeriod?: string | null;
      readonly minimumParticipationUsd: number;
      readonly currency?: string | null;
      readonly gpCommitment?: number | null;
      readonly isDemo: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly id: string;
        readonly publicMediaId: string;
        readonly fileName: string;
        readonly collection: string;
        readonly mimeType?: string | null;
      }>;
      readonly diversification: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
      };
    }>;
    readonly documents: ReadonlyArray<{
      readonly __typename: "Document";
      readonly id: string;
      readonly name: string;
      readonly type: DocumentType;
      readonly approvedAt?: string | null;
      readonly canBeDownloaded: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly id: string;
        readonly publicMediaId: string;
      }>;
    }>;
    readonly contracts: ReadonlyArray<{
      readonly __typename: "Contract";
      readonly id: string;
      readonly name: string;
      readonly type: string;
      readonly approvedAt?: string | null;
      readonly canBeDownloaded: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly id: string;
        readonly publicMediaId: string;
      }>;
    }>;
  };
};

export type AssetsRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type AssetsRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly pendingAllocations: ReadonlyArray<{
      readonly __typename: "PendingAllocation";
      readonly id: string;
      readonly amountUsd: number;
      readonly status: AllocationsStatus;
      readonly asset: { readonly __typename: "Asset"; readonly id: string };
    }>;
    readonly enabledAllocations: ReadonlyArray<{
      readonly __typename: "EnabledAllocation";
      readonly amountUsd: number;
      readonly asset: { readonly __typename: "Asset"; readonly id: string };
    }>;
    readonly openAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly closingDate: string;
      readonly targetGrossIrr?: number | null;
      readonly targetGrossMom?: number | null;
      readonly minimumParticipationUsd: number;
      readonly isDemo: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly publicMediaId: string;
        readonly fileName: string;
      }>;
      readonly diversification: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
      };
    }>;
    readonly closedAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly closingDate: string;
      readonly targetGrossIrr?: number | null;
      readonly targetGrossMom?: number | null;
      readonly minimumParticipationUsd: number;
      readonly isDemo: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly publicMediaId: string;
        readonly fileName: string;
      }>;
      readonly diversification: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
        }>;
      };
    }>;
  };
};

export type InvestorCreateAllocationFormMutationVariables = Exact<{
  input: InvestorCreateOrUpdateAllocationFormInput;
}>;

export type InvestorCreateAllocationFormMutation = {
  readonly __typename: "Mutation";
  readonly submitInvestorCreateOrUpdateAllocationForm: {
    readonly __typename: "InvestorCreateOrUpdateAllocationFormResult";
    readonly result: {
      readonly __typename: "FormSubmitResult";
      readonly success: boolean;
    };
    readonly pendingAllocation?: {
      readonly __typename: "PendingAllocation";
      readonly amountUsd: number;
      readonly asset: {
        readonly __typename: "Asset";
        readonly id: string;
        readonly name: string;
      };
    } | null;
  };
};

export type CashflowAssetPartsFragment = {
  readonly __typename: "Asset";
  readonly id: string;
  readonly name: string;
  readonly closingDate: string;
  readonly committedCapitalCalledPercent: number;
  readonly minDrawdownYear1Percent: number;
  readonly moicLow: number;
  readonly moicHigh: number;
  readonly moicBase: number;
  readonly currency?: string | null;
  readonly jcurveYears: ReadonlyArray<{
    readonly __typename: "JCurveYear";
    readonly year: number;
    readonly drawdownPercent: number;
    readonly distributionPercent: number;
  }>;
};

export type CashflowSimulatorQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
  cutoffs:
    | ReadonlyArray<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
}>;

export type CashflowSimulatorQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly enabledAllocations: ReadonlyArray<{
      readonly __typename: "EnabledAllocation";
      readonly id: string;
      readonly amountUsd: number;
      readonly allocationDate: string;
      readonly asset: {
        readonly __typename: "Asset";
        readonly id: string;
        readonly name: string;
        readonly closingDate: string;
        readonly committedCapitalCalledPercent: number;
        readonly minDrawdownYear1Percent: number;
        readonly moicLow: number;
        readonly moicHigh: number;
        readonly moicBase: number;
        readonly currency?: string | null;
        readonly jcurveYears: ReadonlyArray<{
          readonly __typename: "JCurveYear";
          readonly year: number;
          readonly drawdownPercent: number;
          readonly distributionPercent: number;
        }>;
      };
    }>;
    readonly openAssets: ReadonlyArray<{
      readonly __typename: "Asset";
      readonly id: string;
      readonly name: string;
      readonly closingDate: string;
      readonly committedCapitalCalledPercent: number;
      readonly minDrawdownYear1Percent: number;
      readonly moicLow: number;
      readonly moicHigh: number;
      readonly moicBase: number;
      readonly currency?: string | null;
      readonly jcurveYears: ReadonlyArray<{
        readonly __typename: "JCurveYear";
        readonly year: number;
        readonly drawdownPercent: number;
        readonly distributionPercent: number;
      }>;
    }>;
    readonly portfolioSituation: ReadonlyArray<{
      readonly __typename: "PortfolioSituation";
      readonly byAllocation: ReadonlyArray<{
        readonly __typename: "PortfolioSituationForAllocation";
        readonly paidInUsd: number;
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly id: string;
        };
      }>;
    }>;
  };
};

export type DocumentsRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type DocumentsRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly capitalCalls: ReadonlyArray<{
      readonly __typename: "CapitalCallOperation";
      readonly uuid: string;
      readonly deadlineDate?: string | null;
      readonly transactions: ReadonlyArray<{
        readonly __typename: "CapitalCallTransaction";
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly vehicle: {
            readonly __typename: "InvestmentVehicle";
            readonly name: string;
          };
        };
      }>;
      readonly document: {
        readonly __typename: "CapitalCallDocument";
        readonly link: string;
        readonly read: boolean;
      };
    }>;
    readonly distributions: ReadonlyArray<{
      readonly __typename: "DistributionOperation";
      readonly uuid: string;
      readonly valueDate: string;
      readonly transactions: ReadonlyArray<{
        readonly __typename: "DistributionTransaction";
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly vehicle: {
            readonly __typename: "InvestmentVehicle";
            readonly name: string;
          };
        };
      }>;
      readonly document: {
        readonly __typename: "DistributionDocument";
        readonly link: string;
        readonly read: boolean;
      };
    }>;
    readonly documents: ReadonlyArray<{
      readonly __typename: "Document";
      readonly id: string;
      readonly name: string;
      readonly type: DocumentType;
      readonly approvedAt?: string | null;
      readonly canBeDownloaded: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly publicMediaId: string;
        readonly fileName: string;
      }>;
      readonly assets: ReadonlyArray<{
        readonly __typename: "Asset";
        readonly id: string;
        readonly name: string;
        readonly media: ReadonlyArray<{
          readonly __typename: "Media";
          readonly publicMediaId: string;
          readonly fileName: string;
        }>;
        readonly diversification: {
          readonly __typename: "DiversificationReport";
          readonly strategies: ReadonlyArray<{
            readonly __typename: "DiversificationReportCategory";
            readonly name: string;
          }>;
          readonly industries: ReadonlyArray<{
            readonly __typename: "DiversificationReportCategory";
            readonly name: string;
          }>;
        };
      }>;
    }>;
  };
};

export type LayoutRouteQueryVariables = Exact<{ [key: string]: never }>;

export type LayoutRouteQuery = {
  readonly __typename: "Query";
  readonly authUser: {
    readonly __typename: "User";
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly featureFlags: ReadonlyArray<FeatureFlag>;
    readonly passwordChangedAt?: string | null;
    readonly authenticators: ReadonlyArray<{
      readonly __typename: "Authenticator";
      readonly id: string;
      readonly createdPlatform: AuthenticatorCreatedPlatform;
      readonly createdFromClient: AuthenticatorCreatedFromClient;
      readonly createdDevice?: string | null;
      readonly createdAt?: string | null;
      readonly lastUsed?: string | null;
    }>;
    readonly investors: ReadonlyArray<{
      readonly __typename: "UserInvestor";
      readonly roles: ReadonlyArray<UserInvestorRole>;
      readonly investor: {
        readonly __typename: "Investor";
        readonly id: string;
        readonly businessName: string;
        readonly type: InvestorType;
        readonly defaultRoute: DashboardRoute;
        readonly approvedAt?: string | null;
        readonly rejectedAt?: string | null;
        readonly adminApprovedAt?: string | null;
        readonly adminRejectedAt?: string | null;
        readonly investmentVehicles: ReadonlyArray<{
          readonly __typename: "InvestmentVehicle";
          readonly id: string;
          readonly name: string;
        }>;
        readonly firstAllocation?: {
          readonly __typename: "EnabledAllocation";
          readonly allocationDate: string;
        } | null;
      };
    }>;
  };
  readonly configuration: {
    readonly __typename: "Configuration";
    readonly defaultQuarter: string;
  };
};

export type ManageInvestorRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type ManageInvestorRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly id: string;
    readonly role: string;
    readonly businessName: string;
    readonly address?: string | null;
    readonly vat?: string | null;
    readonly phone?: string | null;
    readonly countryOfResidence?: string | null;
    readonly country?: string | null;
    readonly city?: string | null;
    readonly nationality?: string | null;
  };
};

export type SubmitInvestorAccountFormMutationVariables = Exact<{
  investorId: Scalars["String"]["input"];
  input: InvestorAccountFormInput;
}>;

export type SubmitInvestorAccountFormMutation = {
  readonly __typename: "Mutation";
  readonly submitInvestorAccountForm: {
    readonly __typename: "FormSubmitResult";
    readonly success: boolean;
    readonly message?: string | null;
    readonly validationErrors?: ReadonlyArray<{
      readonly __typename: "ValidationError";
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};

export type PortfolioRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
  cutoffs:
    | ReadonlyArray<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  flags?: InputMaybe<ReadonlyArray<FeatureFlag> | FeatureFlag>;
}>;

export type PortfolioRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly portfolioSituation: ReadonlyArray<{
      readonly __typename: "PortfolioSituation";
      readonly cutoffDate: string;
      readonly byAllocation: ReadonlyArray<{
        readonly __typename: "PortfolioSituationForAllocation";
        readonly paidInUsd: number;
        readonly sourcePaidInUsd: number;
        readonly distributedUsd: number;
        readonly dpi: number;
        readonly navUsd: number;
        readonly mom: number;
        readonly irr: number;
        readonly profitUsd: number;
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly allocationDate: string;
          readonly amountUsd: number;
          readonly currency: string;
          readonly asset: {
            readonly __typename: "Asset";
            readonly id: string;
            readonly name: string;
            readonly firstCapitalCallDate?: string | null;
            readonly committedCapitalCalledPercent: number;
          };
        };
      }>;
      readonly annotatedByAllocation: ReadonlyArray<{
        readonly __typename: "PortfolioSituationAnnotation";
        readonly trailJson_UNSTABLE?: string | null;
      }>;
      readonly totals: {
        readonly __typename: "PortfolioSituationTotals";
        readonly allocationAmountUsd: number;
        readonly paidInUsd: number;
        readonly distributedUsd: number;
        readonly navUsd: number;
        readonly dpi: number;
        readonly mom: number;
        readonly irr: number;
        readonly profitUsd: number;
      };
      readonly pendingAllocations: ReadonlyArray<{
        readonly __typename: "PendingAllocation";
        readonly amountUsd: number;
        readonly asset: {
          readonly __typename: "Asset";
          readonly id: string;
          readonly name: string;
        };
      }>;
      readonly pendingTotals: {
        readonly __typename: "PortfolioSituationPendingTotals";
        readonly allocationAmountUsd: number;
      };
      readonly companies: ReadonlyArray<{
        readonly __typename: "PortfolioSituationCompany";
        readonly id: string;
        readonly name: string;
        readonly amountUsd?: number | null;
        readonly navUsd?: number | null;
        readonly mom?: number | null;
        readonly percentage?: number | null;
        readonly sector?: string | null;
        readonly region?: string | null;
        readonly website?: string | null;
        readonly asset: {
          readonly __typename: "Asset";
          readonly id: string;
          readonly name: string;
        };
      }>;
      readonly diversificationByAllocationAmount: {
        readonly __typename: "DiversificationReport";
        readonly strategies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly industries: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
        readonly geographies: ReadonlyArray<{
          readonly __typename: "DiversificationReportCategory";
          readonly name: string;
          readonly amount: number;
        }>;
      };
    }>;
  };
};

export type RetrieveAllocationsQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type RetrieveAllocationsQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly enabledAllocations: ReadonlyArray<{
      readonly __typename: "EnabledAllocation";
      readonly allocationDate: string;
    }>;
  };
};

export type PortfolioAmountsChartQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
  cutoffs:
    | ReadonlyArray<Scalars["String"]["input"]>
    | Scalars["String"]["input"];
  flags?: InputMaybe<ReadonlyArray<FeatureFlag> | FeatureFlag>;
}>;

export type PortfolioAmountsChartQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly portfolioSituation: ReadonlyArray<{
      readonly __typename: "PortfolioSituation";
      readonly cutoffDate: string;
      readonly totals: {
        readonly __typename: "PortfolioSituationTotals";
        readonly paidInUsd: number;
        readonly navUsd: number;
      };
    }>;
  };
};

export type SharedDocumentsRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type SharedDocumentsRouteQuery = {
  readonly __typename: "Query";
  readonly investorVdr: {
    readonly __typename: "InvestorVdr";
    readonly documents: ReadonlyArray<{
      readonly __typename: "Document";
      readonly id: string;
      readonly name: string;
      readonly type: DocumentType;
      readonly approvedAt?: string | null;
      readonly canBeDownloaded: boolean;
      readonly media: ReadonlyArray<{
        readonly __typename: "Media";
        readonly publicMediaId: string;
        readonly fileName: string;
      }>;
      readonly assets: ReadonlyArray<{
        readonly __typename: "Asset";
        readonly id: string;
        readonly name: string;
        readonly media: ReadonlyArray<{
          readonly __typename: "Media";
          readonly publicMediaId: string;
          readonly fileName: string;
        }>;
        readonly diversification: {
          readonly __typename: "DiversificationReport";
          readonly strategies: ReadonlyArray<{
            readonly __typename: "DiversificationReportCategory";
            readonly name: string;
          }>;
          readonly industries: ReadonlyArray<{
            readonly __typename: "DiversificationReportCategory";
            readonly name: string;
          }>;
        };
      }>;
    }>;
  };
};

export type TransactionsRouteQueryVariables = Exact<{
  investorId: Scalars["String"]["input"];
}>;

export type TransactionsRouteQuery = {
  readonly __typename: "Query";
  readonly investor: {
    readonly __typename: "Investor";
    readonly capitalCalls: ReadonlyArray<{
      readonly __typename: "CapitalCallOperation";
      readonly uuid: string;
      readonly deadlineDate?: string | null;
      readonly transactions: ReadonlyArray<{
        readonly __typename: "CapitalCallTransaction";
        readonly calledAmount: number;
        readonly paidAmount: number;
        readonly isPaid: boolean;
        readonly isPendingFx: boolean;
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly currency: string;
          readonly asset: {
            readonly __typename: "Asset";
            readonly id: string;
            readonly name: string;
          };
        };
      }>;
      readonly document: {
        readonly __typename: "CapitalCallDocument";
        readonly link: string;
        readonly read: boolean;
      };
    }>;
    readonly distributions: ReadonlyArray<{
      readonly __typename: "DistributionOperation";
      readonly uuid: string;
      readonly valueDate: string;
      readonly transactions: ReadonlyArray<{
        readonly __typename: "DistributionTransaction";
        readonly amount: number;
        readonly isPaid: boolean;
        readonly allocation: {
          readonly __typename: "EnabledAllocation";
          readonly id: string;
          readonly currency: string;
          readonly asset: {
            readonly __typename: "Asset";
            readonly id: string;
            readonly name: string;
          };
        };
      }>;
      readonly document: {
        readonly __typename: "DistributionDocument";
        readonly link: string;
        readonly read: boolean;
      };
    }>;
    readonly bankAccounts?: ReadonlyArray<{
      readonly __typename: "InvestorBankAccount";
      readonly id: string;
      readonly bankName: string;
      readonly bankAddress: string;
      readonly accountHolder: string;
      readonly iban?: string | null;
      readonly accountNumber?: string | null;
      readonly swift?: string | null;
      readonly currency: string;
      readonly status: BankAccountStatus;
      readonly createdAt: string;
    }> | null;
  };
};

export type SubmitBankAccountFormMutationVariables = Exact<{
  input: InvestorBankAccountFormInput;
}>;

export type SubmitBankAccountFormMutation = {
  readonly __typename: "Mutation";
  readonly submitInvestorBankAccountForm: {
    readonly __typename: "FormSubmitResult";
    readonly success: boolean;
    readonly message?: string | null;
    readonly validationErrors?: ReadonlyArray<{
      readonly __typename: "ValidationError";
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};

export type SubmitUserAccountFormMutationVariables = Exact<{
  input: UserAccountFormInput;
}>;

export type SubmitUserAccountFormMutation = {
  readonly __typename: "Mutation";
  readonly submitUserAccountForm: {
    readonly __typename: "FormSubmitResult";
    readonly success: boolean;
    readonly message?: string | null;
    readonly validationErrors?: ReadonlyArray<{
      readonly __typename: "ValidationError";
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};

export type SubmitUserPasswordFormMutationVariables = Exact<{
  input: UserPasswordFormInput;
}>;

export type SubmitUserPasswordFormMutation = {
  readonly __typename: "Mutation";
  readonly submitUserPasswordForm: {
    readonly __typename: "FormSubmitResult";
    readonly success: boolean;
    readonly message?: string | null;
    readonly validationErrors?: ReadonlyArray<{
      readonly __typename: "ValidationError";
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};

export const AssetPartsFragmentDoc = gql`
  fragment AssetParts on Asset {
    id
    name
    description
    closingDate
    targetSize
    targetGrossIrr
    targetGrossMom
    lifetime
    investmentPeriod
    minimumParticipationUsd
    currency
    gpCommitment
    isDemo
    media {
      id
      publicMediaId
      fileName
      collection
      mimeType
    }
    diversification {
      strategies {
        name
        amount
      }
      geographies {
        name
        amount
      }
      industries {
        name
        amount
      }
    }
  }
`;
export const CashflowAssetPartsFragmentDoc = gql`
  fragment CashflowAssetParts on Asset {
    id
    name
    closingDate
    committedCapitalCalledPercent
    minDrawdownYear1Percent
    moicLow
    moicHigh
    moicBase
    jcurveYears {
      year
      drawdownPercent
      distributionPercent
    }
    currency
  }
`;
export const DeleteAuthenticatorDocument = gql`
  mutation DeleteAuthenticator($input: ID!) {
    deleteAuthenticator(id: $input) {
      ... on DeleteAuthenticatorSuccess {
        success
      }
      ... on DeleteAuthenticatorError {
        message
      }
    }
  }
`;

export const DeleteAuthenticatorComponent = (
  props: Omit<
    Urql.MutationProps<
      DeleteAuthenticatorMutation,
      DeleteAuthenticatorMutationVariables
    >,
    "query"
  > & { variables?: DeleteAuthenticatorMutationVariables },
) => <Urql.Mutation {...props} query={DeleteAuthenticatorDocument} />;

export function useDeleteAuthenticatorMutation() {
  return Urql.useMutation<
    DeleteAuthenticatorMutation,
    DeleteAuthenticatorMutationVariables
  >(DeleteAuthenticatorDocument);
}
export const AssetRouteDocument = gql`
  query AssetRoute($investorId: String!, $assetId: String!) {
    investor(investorId: $investorId) {
      openAssets {
        ...AssetParts
      }
      closedAssets {
        ...AssetParts
      }
      enabledAssets {
        ...AssetParts
      }
      documents(assetId: $assetId) {
        id
        name
        type
        approvedAt
        canBeDownloaded
        media {
          id
          publicMediaId
        }
      }
      contracts(assetId: $assetId) {
        id
        name
        type
        approvedAt
        canBeDownloaded
        media {
          id
          publicMediaId
        }
      }
    }
  }
  ${AssetPartsFragmentDoc}
`;

export const AssetRouteComponent = (
  props: Omit<
    Urql.QueryProps<AssetRouteQuery, AssetRouteQueryVariables>,
    "query"
  > & { variables: AssetRouteQueryVariables },
) => <Urql.Query {...props} query={AssetRouteDocument} />;

export function useAssetRouteQuery(
  options: Omit<Urql.UseQueryArgs<AssetRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<AssetRouteQuery, AssetRouteQueryVariables>({
    query: AssetRouteDocument,
    ...options,
  });
}
export const AssetsRouteDocument = gql`
  query AssetsRoute($investorId: String!) {
    investor(investorId: $investorId) {
      pendingAllocations {
        id
        amountUsd
        status
        asset {
          id
        }
      }
      enabledAllocations {
        amountUsd
        asset {
          id
        }
      }
      openAssets {
        id
        name
        description
        closingDate
        targetGrossIrr
        targetGrossMom
        minimumParticipationUsd
        isDemo
        media {
          publicMediaId
          fileName
        }
        diversification {
          strategies {
            name
            amount
          }
          geographies {
            name
            amount
          }
          industries {
            name
            amount
          }
        }
      }
      closedAssets {
        id
        name
        description
        closingDate
        targetGrossIrr
        targetGrossMom
        minimumParticipationUsd
        isDemo
        media {
          publicMediaId
          fileName
        }
        diversification {
          strategies {
            name
            amount
          }
          geographies {
            name
          }
          industries {
            name
          }
        }
      }
    }
  }
`;

export const AssetsRouteComponent = (
  props: Omit<
    Urql.QueryProps<AssetsRouteQuery, AssetsRouteQueryVariables>,
    "query"
  > & { variables: AssetsRouteQueryVariables },
) => <Urql.Query {...props} query={AssetsRouteDocument} />;

export function useAssetsRouteQuery(
  options: Omit<Urql.UseQueryArgs<AssetsRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<AssetsRouteQuery, AssetsRouteQueryVariables>({
    query: AssetsRouteDocument,
    ...options,
  });
}
export const InvestorCreateAllocationFormDocument = gql`
  mutation InvestorCreateAllocationForm(
    $input: InvestorCreateOrUpdateAllocationFormInput!
  ) {
    submitInvestorCreateOrUpdateAllocationForm(input: $input) {
      result {
        success
      }
      pendingAllocation {
        asset {
          id
          name
        }
        amountUsd
      }
    }
  }
`;

export const InvestorCreateAllocationFormComponent = (
  props: Omit<
    Urql.MutationProps<
      InvestorCreateAllocationFormMutation,
      InvestorCreateAllocationFormMutationVariables
    >,
    "query"
  > & { variables?: InvestorCreateAllocationFormMutationVariables },
) => <Urql.Mutation {...props} query={InvestorCreateAllocationFormDocument} />;

export function useInvestorCreateAllocationFormMutation() {
  return Urql.useMutation<
    InvestorCreateAllocationFormMutation,
    InvestorCreateAllocationFormMutationVariables
  >(InvestorCreateAllocationFormDocument);
}
export const CashflowSimulatorDocument = gql`
  query CashflowSimulator($investorId: String!, $cutoffs: [String!]!) {
    investor(investorId: $investorId) {
      enabledAllocations {
        id
        amountUsd
        allocationDate
        asset {
          ...CashflowAssetParts
        }
      }
      openAssets {
        ...CashflowAssetParts
      }
      portfolioSituation: portfolioSituation(cutoffs: $cutoffs) {
        byAllocation {
          allocation {
            id
          }
          paidInUsd
        }
      }
    }
  }
  ${CashflowAssetPartsFragmentDoc}
`;

export const CashflowSimulatorComponent = (
  props: Omit<
    Urql.QueryProps<CashflowSimulatorQuery, CashflowSimulatorQueryVariables>,
    "query"
  > & { variables: CashflowSimulatorQueryVariables },
) => <Urql.Query {...props} query={CashflowSimulatorDocument} />;

export function useCashflowSimulatorQuery(
  options: Omit<Urql.UseQueryArgs<CashflowSimulatorQueryVariables>, "query">,
) {
  return Urql.useQuery<CashflowSimulatorQuery, CashflowSimulatorQueryVariables>(
    { query: CashflowSimulatorDocument, ...options },
  );
}
export const DocumentsRouteDocument = gql`
  query DocumentsRoute($investorId: String!) {
    investor(investorId: $investorId) {
      capitalCalls {
        uuid
        deadlineDate
        transactions {
          allocation {
            vehicle {
              name
            }
          }
        }
        document(investorId: $investorId) {
          link
          read
        }
      }
      distributions {
        uuid
        valueDate
        transactions {
          allocation {
            vehicle {
              name
            }
          }
        }
        document {
          link
          read
        }
      }
      documents {
        id
        name
        type
        approvedAt
        canBeDownloaded
        media {
          publicMediaId
          fileName
        }
        assets {
          id
          name
          media {
            publicMediaId
            fileName
          }
          diversification {
            strategies {
              name
            }
            industries {
              name
            }
          }
        }
      }
    }
  }
`;

export const DocumentsRouteComponent = (
  props: Omit<
    Urql.QueryProps<DocumentsRouteQuery, DocumentsRouteQueryVariables>,
    "query"
  > & { variables: DocumentsRouteQueryVariables },
) => <Urql.Query {...props} query={DocumentsRouteDocument} />;

export function useDocumentsRouteQuery(
  options: Omit<Urql.UseQueryArgs<DocumentsRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<DocumentsRouteQuery, DocumentsRouteQueryVariables>({
    query: DocumentsRouteDocument,
    ...options,
  });
}
export const LayoutRouteDocument = gql`
  query LayoutRoute {
    authUser {
      id
      email
      firstName
      lastName
      featureFlags
      passwordChangedAt
      authenticators {
        id
        createdPlatform
        createdFromClient
        createdDevice
        createdAt
        lastUsed
      }
      investors {
        roles
        investor {
          id
          businessName
          type
          defaultRoute
          approvedAt
          rejectedAt
          adminApprovedAt
          adminRejectedAt
          investmentVehicles {
            id
            name
          }
          firstAllocation {
            allocationDate
          }
        }
      }
    }
    configuration {
      defaultQuarter
    }
  }
`;

export const LayoutRouteComponent = (
  props: Omit<
    Urql.QueryProps<LayoutRouteQuery, LayoutRouteQueryVariables>,
    "query"
  > & { variables?: LayoutRouteQueryVariables },
) => <Urql.Query {...props} query={LayoutRouteDocument} />;

export function useLayoutRouteQuery(
  options?: Omit<Urql.UseQueryArgs<LayoutRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<LayoutRouteQuery, LayoutRouteQueryVariables>({
    query: LayoutRouteDocument,
    ...options,
  });
}
export const ManageInvestorRouteDocument = gql`
  query ManageInvestorRoute($investorId: String!) {
    investor(investorId: $investorId) {
      id
      role
      businessName
      address
      vat
      phone
      countryOfResidence
      country
      city
      nationality
    }
  }
`;

export const ManageInvestorRouteComponent = (
  props: Omit<
    Urql.QueryProps<
      ManageInvestorRouteQuery,
      ManageInvestorRouteQueryVariables
    >,
    "query"
  > & { variables: ManageInvestorRouteQueryVariables },
) => <Urql.Query {...props} query={ManageInvestorRouteDocument} />;

export function useManageInvestorRouteQuery(
  options: Omit<Urql.UseQueryArgs<ManageInvestorRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<
    ManageInvestorRouteQuery,
    ManageInvestorRouteQueryVariables
  >({ query: ManageInvestorRouteDocument, ...options });
}
export const SubmitInvestorAccountFormDocument = gql`
  mutation SubmitInvestorAccountForm(
    $investorId: String!
    $input: InvestorAccountFormInput!
  ) {
    submitInvestorAccountForm(investorId: $investorId, input: $input) {
      success
      message
      validationErrors {
        field
        message
      }
    }
  }
`;

export const SubmitInvestorAccountFormComponent = (
  props: Omit<
    Urql.MutationProps<
      SubmitInvestorAccountFormMutation,
      SubmitInvestorAccountFormMutationVariables
    >,
    "query"
  > & { variables?: SubmitInvestorAccountFormMutationVariables },
) => <Urql.Mutation {...props} query={SubmitInvestorAccountFormDocument} />;

export function useSubmitInvestorAccountFormMutation() {
  return Urql.useMutation<
    SubmitInvestorAccountFormMutation,
    SubmitInvestorAccountFormMutationVariables
  >(SubmitInvestorAccountFormDocument);
}
export const PortfolioRouteDocument = gql`
  query PortfolioRoute(
    $investorId: String!
    $cutoffs: [String!]!
    $flags: [FeatureFlag!]
  ) {
    investor(investorId: $investorId) {
      portfolioSituation(cutoffs: $cutoffs, flags: $flags) {
        cutoffDate
        byAllocation {
          allocation {
            allocationDate
            amountUsd
            currency
            asset {
              id
              name
              firstCapitalCallDate
              committedCapitalCalledPercent
            }
          }
          paidInUsd
          sourcePaidInUsd
          distributedUsd
          dpi
          navUsd
          mom
          irr
          profitUsd
        }
        annotatedByAllocation {
          trailJson_UNSTABLE
        }
        totals {
          allocationAmountUsd
          paidInUsd
          distributedUsd
          navUsd
          dpi
          mom
          irr
          profitUsd
        }
        pendingAllocations {
          amountUsd
          asset {
            id
            name
          }
        }
        pendingTotals {
          allocationAmountUsd
        }
        companies {
          id
          name
          amountUsd
          navUsd
          mom
          percentage
          sector
          region
          website
          asset {
            id
            name
          }
        }
        diversificationByAllocationAmount {
          strategies {
            name
            amount
          }
          industries {
            name
            amount
          }
          geographies {
            name
            amount
          }
        }
      }
    }
  }
`;

export const PortfolioRouteComponent = (
  props: Omit<
    Urql.QueryProps<PortfolioRouteQuery, PortfolioRouteQueryVariables>,
    "query"
  > & { variables: PortfolioRouteQueryVariables },
) => <Urql.Query {...props} query={PortfolioRouteDocument} />;

export function usePortfolioRouteQuery(
  options: Omit<Urql.UseQueryArgs<PortfolioRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<PortfolioRouteQuery, PortfolioRouteQueryVariables>({
    query: PortfolioRouteDocument,
    ...options,
  });
}
export const RetrieveAllocationsDocument = gql`
  query RetrieveAllocations($investorId: String!) {
    investor(investorId: $investorId) {
      enabledAllocations {
        allocationDate
      }
    }
  }
`;

export const RetrieveAllocationsComponent = (
  props: Omit<
    Urql.QueryProps<
      RetrieveAllocationsQuery,
      RetrieveAllocationsQueryVariables
    >,
    "query"
  > & { variables: RetrieveAllocationsQueryVariables },
) => <Urql.Query {...props} query={RetrieveAllocationsDocument} />;

export function useRetrieveAllocationsQuery(
  options: Omit<Urql.UseQueryArgs<RetrieveAllocationsQueryVariables>, "query">,
) {
  return Urql.useQuery<
    RetrieveAllocationsQuery,
    RetrieveAllocationsQueryVariables
  >({ query: RetrieveAllocationsDocument, ...options });
}
export const PortfolioAmountsChartDocument = gql`
  query PortfolioAmountsChart(
    $investorId: String!
    $cutoffs: [String!]!
    $flags: [FeatureFlag!]
  ) {
    investor(investorId: $investorId) {
      portfolioSituation(cutoffs: $cutoffs, flags: $flags) {
        cutoffDate
        totals {
          paidInUsd
          navUsd
        }
      }
    }
  }
`;

export const PortfolioAmountsChartComponent = (
  props: Omit<
    Urql.QueryProps<
      PortfolioAmountsChartQuery,
      PortfolioAmountsChartQueryVariables
    >,
    "query"
  > & { variables: PortfolioAmountsChartQueryVariables },
) => <Urql.Query {...props} query={PortfolioAmountsChartDocument} />;

export function usePortfolioAmountsChartQuery(
  options: Omit<
    Urql.UseQueryArgs<PortfolioAmountsChartQueryVariables>,
    "query"
  >,
) {
  return Urql.useQuery<
    PortfolioAmountsChartQuery,
    PortfolioAmountsChartQueryVariables
  >({ query: PortfolioAmountsChartDocument, ...options });
}
export const SharedDocumentsRouteDocument = gql`
  query SharedDocumentsRoute($investorId: String!) {
    investorVdr(investorId: $investorId) {
      documents {
        id
        name
        type
        approvedAt
        canBeDownloaded
        media {
          publicMediaId
          fileName
        }
        assets {
          id
          name
          media {
            publicMediaId
            fileName
          }
          diversification {
            strategies {
              name
            }
            industries {
              name
            }
          }
        }
      }
    }
  }
`;

export const SharedDocumentsRouteComponent = (
  props: Omit<
    Urql.QueryProps<
      SharedDocumentsRouteQuery,
      SharedDocumentsRouteQueryVariables
    >,
    "query"
  > & { variables: SharedDocumentsRouteQueryVariables },
) => <Urql.Query {...props} query={SharedDocumentsRouteDocument} />;

export function useSharedDocumentsRouteQuery(
  options: Omit<Urql.UseQueryArgs<SharedDocumentsRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<
    SharedDocumentsRouteQuery,
    SharedDocumentsRouteQueryVariables
  >({ query: SharedDocumentsRouteDocument, ...options });
}
export const TransactionsRouteDocument = gql`
  query TransactionsRoute($investorId: String!) {
    investor(investorId: $investorId) {
      capitalCalls {
        uuid
        deadlineDate
        transactions {
          calledAmount
          paidAmount
          isPaid
          isPendingFx
          allocation {
            currency
            asset {
              id
              name
            }
          }
        }
        document(investorId: $investorId) {
          link
          read
        }
      }
      distributions {
        uuid
        valueDate
        transactions {
          amount
          isPaid
          allocation {
            id
            currency
            asset {
              id
              name
            }
          }
        }
        document {
          link
          read
        }
      }
      bankAccounts {
        id
        bankName
        bankAddress
        accountHolder
        iban
        accountNumber
        swift
        currency
        status
        createdAt
      }
    }
  }
`;

export const TransactionsRouteComponent = (
  props: Omit<
    Urql.QueryProps<TransactionsRouteQuery, TransactionsRouteQueryVariables>,
    "query"
  > & { variables: TransactionsRouteQueryVariables },
) => <Urql.Query {...props} query={TransactionsRouteDocument} />;

export function useTransactionsRouteQuery(
  options: Omit<Urql.UseQueryArgs<TransactionsRouteQueryVariables>, "query">,
) {
  return Urql.useQuery<TransactionsRouteQuery, TransactionsRouteQueryVariables>(
    { query: TransactionsRouteDocument, ...options },
  );
}
export const SubmitBankAccountFormDocument = gql`
  mutation SubmitBankAccountForm($input: InvestorBankAccountFormInput!) {
    submitInvestorBankAccountForm(input: $input) {
      success
      message
      validationErrors {
        field
        message
      }
    }
  }
`;

export const SubmitBankAccountFormComponent = (
  props: Omit<
    Urql.MutationProps<
      SubmitBankAccountFormMutation,
      SubmitBankAccountFormMutationVariables
    >,
    "query"
  > & { variables?: SubmitBankAccountFormMutationVariables },
) => <Urql.Mutation {...props} query={SubmitBankAccountFormDocument} />;

export function useSubmitBankAccountFormMutation() {
  return Urql.useMutation<
    SubmitBankAccountFormMutation,
    SubmitBankAccountFormMutationVariables
  >(SubmitBankAccountFormDocument);
}
export const SubmitUserAccountFormDocument = gql`
  mutation SubmitUserAccountForm($input: UserAccountFormInput!) {
    submitUserAccountForm(input: $input) {
      success
      message
      validationErrors {
        field
        message
      }
    }
  }
`;

export const SubmitUserAccountFormComponent = (
  props: Omit<
    Urql.MutationProps<
      SubmitUserAccountFormMutation,
      SubmitUserAccountFormMutationVariables
    >,
    "query"
  > & { variables?: SubmitUserAccountFormMutationVariables },
) => <Urql.Mutation {...props} query={SubmitUserAccountFormDocument} />;

export function useSubmitUserAccountFormMutation() {
  return Urql.useMutation<
    SubmitUserAccountFormMutation,
    SubmitUserAccountFormMutationVariables
  >(SubmitUserAccountFormDocument);
}
export const SubmitUserPasswordFormDocument = gql`
  mutation SubmitUserPasswordForm($input: UserPasswordFormInput!) {
    submitUserPasswordForm(input: $input) {
      success
      message
      validationErrors {
        field
        message
      }
    }
  }
`;

export const SubmitUserPasswordFormComponent = (
  props: Omit<
    Urql.MutationProps<
      SubmitUserPasswordFormMutation,
      SubmitUserPasswordFormMutationVariables
    >,
    "query"
  > & { variables?: SubmitUserPasswordFormMutationVariables },
) => <Urql.Mutation {...props} query={SubmitUserPasswordFormDocument} />;

export function useSubmitUserPasswordFormMutation() {
  return Urql.useMutation<
    SubmitUserPasswordFormMutation,
    SubmitUserPasswordFormMutationVariables
  >(SubmitUserPasswordFormDocument);
}
