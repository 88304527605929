import { useMutation } from "@tanstack/react-query";
import { UserPasswordFormInput } from "../../generated/urql";
import { httpClient } from "../httpClient";

/**
 * Execute the password change mutation toward the REST api.
 */
export function usePasswordChangeMutation() {
  return useMutation({
    mutationFn: (params: UserPasswordFormInput) =>
      httpClient.post("/auth/password/change", params, {
        headers: { "x-heritage-action": "password-change" },
        validateStatus: (status) => status !== 500,
      }),
  });
}
