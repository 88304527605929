import React from "react";

import Paper from "@mui/material/Paper";

export const ContainerPaper: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Paper
    elevation={0}
    sx={{
      p: 3,
      marginTop: "20px",
      boxShadow: "0px 0px 20px rgb(31 30 30 / 10%);",
      borderRadius: "4px",
    }}
  >
    {children}
  </Paper>
);
